import React, { Fragment } from 'react';

//Styles
import { FooterStyles } from '../../styles/gstyles';

//Images
import {ReactComponent as Logo} from '../../assets/imagenes/logom-footer.svg';

const Footer = () => {
	return (
		<Fragment>

			{/* // Footer Styles */}
			<FooterStyles />

			<footer className="footer">
				<a href="#home">
					<Logo />
				</a>
				<ul className="footer__list">
					<li><a href="https://www.instagram.com/yomellamo/?hl=es" target="_blank">Instagram</a></li>
					<li><span>|</span><a  href="https://www.facebook.com/Yomellamo" target="_blank">Facebook</a><span>|</span></li>
					<li><a  href="https://twitter.com/YomeLlamo?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">Twitter</a></li>
				</ul>
			</footer>

		</Fragment>
	 );
}

export default Footer;