import React, { Fragment, useState } from 'react';

//Libraries
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//Layout

//Styles
import { GalleryMobileStyles } from '../../styles/gstyles';
import Bogota from './ciudadesmobile/bogota';
import Medellin from './ciudadesmobile/medellin';
import Barranquilla from './ciudadesmobile/barranquilla';
import Bucaramanga from './ciudadesmobile/bucaramanga';

//Images

const GalleryMobile = () => {

	const [tabIndex, setTabIndex] = useState(0);

	return (
		<Fragment>

			{/* // GalleryMobile Styles */}
			<GalleryMobileStyles />

			<div className="gallery">
				<Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
					<div className="gallery--list__content">
						<TabList className="gallery--list">
							<Tab className="gallery--list__item">Bogotá</Tab>
							<Tab className="gallery--list__item">Medellín</Tab>
							<Tab className="gallery--list__item">Barranquílla</Tab>
							<Tab className="gallery--list__item">Bucaramanga</Tab>
						</TabList>
					</div>

				<div className="gallery--content">
					<TabPanel className="gallery--content__item">
						<Bogota />
					</TabPanel>
					<TabPanel className="gallery--content__item">
						<Medellin />
					</TabPanel>
					<TabPanel className="gallery--content__item">
						<Barranquilla />
					</TabPanel>
					<TabPanel className="gallery--content__item">
						<Bucaramanga />
					</TabPanel>
				</div>
				</Tabs>
			</div>
		</Fragment>
	 );
}

export default GalleryMobile;