import React, { Fragment, useState } from 'react';

//Data
import carouseldata from '../../assets/data/carouselData';

//Libraries
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from 'react-responsive';
import { Image, Shimmer } from 'react-shimmer';

//Layout

//Styles
import { CarouselStyles } from '../../styles/gstyles';

//Images
import Prev from '../../assets/imagenes/btn-transparent.png'
import Next from '../../assets/imagenes/btn-transparent.png';

import one from '../../assets/imagenes/carousel/temporada_1.jpg';
import two from '../../assets/imagenes/carousel/temporada_2.jpg';
import three from '../../assets/imagenes/carousel/temporada_3.jpg';
import four from '../../assets/imagenes/carousel/temporada_4.jpg';
import five from '../../assets/imagenes/carousel/temporada_5.jpg';
import six from '../../assets/imagenes/carousel/temporada_6.jpg';
import seven from '../../assets/imagenes/carousel/temporada_7.jpg';

const CarouselSectionOne = () => {

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	//State of data
	const [getCarousel, setCarousel] = useState(carouseldata);

	//State of carousel
	const [getAutoPlay, setAutoPlay] = useState(true);

	//Libraries-Carousel
	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 1
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1
		}
	};

	const removeClassSibligs = () => {
		const onlyItemZero = document.querySelector('.carousel-container .sibling_zero')
		const onlyItemOne = document.querySelector('.carousel-container .sibling_one')

		if(onlyItemZero){
			onlyItemZero.classList.remove('sibling_zero');
		}

		if(onlyItemOne){
			onlyItemOne.classList.remove('sibling_one');
		}
	}

	const addClassSibligs = () => {
		if(getAutoPlay){
			setAutoPlay(false);
		}else{
			removeClassSibligs();
		}

		const activeItemNext = document.querySelector('.carousel-container .react-multi-carousel-item--active').nextElementSibling;

		const activeItemPrev = document.querySelector('.carousel-container .react-multi-carousel-item--active').previousElementSibling;

		activeItemPrev.classList.add('sibling_zero');
		activeItemNext.classList.add('sibling_one');
	}

	const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
		const { carouselState: { currentSlide } } = rest;
		return (
			<div className="carousel-button-group">
				<img src={Prev} className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} />
				<img src={Next}  onClick={() => next()} />
			</div>
		);
	};

	return (
		<Fragment>

			{/* // Carousel Styles */}
			<CarouselStyles />

			<Carousel
				swipeable={isDesktopOrLaptop ? true : true}
				draggable={isDesktopOrLaptop ? true : true}
				showDots={isDesktopOrLaptop || isBigScreen ? true : false}
				responsive={responsive}
				ssr={true} // means to render carousel on server-side.
				infinite={true}
				//autoPlay={this.props.deviceType !== "mobile" ? true : false}
				autoPlay={getAutoPlay}
				autoPlaySpeed={1}
				keyBoardControl={true}
				customTransition="all 2"
				transitionDuration={500}
				containerClass="carousel-container"
				removeArrowOnDeviceType={["tablet", "mobile"]}
				//deviceType={this.props.deviceType}
				dotListClass="custom-dot-list-style"
				itemClass="carousel--item"
				className="carousel"
				afterChange={() => addClassSibligs()}
				beforeChange={() => removeClassSibligs()}
				arrows={isDesktopOrLaptop || isBigScreen ? true : false}
				customButtonGroup={isDesktopOrLaptop || isBigScreen ? null : null}
				minimumTouchDrag={5}
			>
				<div>
					<div className="carousel--item__circle">
						<div className="carousel--item__img">
							<Image
								src={seven}
								fallback={<Shimmer id="shimmer" width={407} height={407} />}
							/>
						</div>
						<div className="carousel--item__rating">
							<p>{getCarousel['Seven'].rating}</p>
							<span>RATING</span>
						</div>
					</div>
					<div className="carousel--item__text">
						<h3>{getCarousel['Seven'].title}</h3>
						<span>{getCarousel['Seven'].date}</span>
					</div>
				</div>

				<div>
					<div className="carousel--item__circle">
						<div className="carousel--item__img">
							<Image
								src={one}
								fallback={<Shimmer id="shimmer" width={407} height={407} />}
							/>
						</div>
						<div className="carousel--item__rating">
							<p>{getCarousel['One'].rating}</p>
							<span>RATING</span>
						</div>
					</div>
					<div className="carousel--item__text">
						<h3>{getCarousel['One'].title}</h3>
						<span>{getCarousel['One'].date}</span>
					</div>
				</div>

				<div>
					<div className="carousel--item__circle">
						<div className="carousel--item__img">
							<Image
								src={two}
								fallback={<Shimmer id="shimmer" width={407} height={407} />}
							/>
						</div>
						<div className="carousel--item__rating">
							<p>{getCarousel['Two'].rating}</p>
							<span>RATING</span>
						</div>
					</div>
					<div className="carousel--item__text">
						<h3>{getCarousel['Two'].title}</h3>
						<span>{getCarousel['Two'].date}</span>
					</div>
				</div>

				<div>
					<div className="carousel--item__circle">
						<div className="carousel--item__img">
							<Image
								src={three}
								fallback={<Shimmer id="shimmer" width={407} height={407} />}
							/>
						</div>
						<div className="carousel--item__rating">
							<p>{getCarousel['Three'].rating}</p>
							<span>RATING</span>
						</div>
					</div>
					<div className="carousel--item__text">
						<h3>{getCarousel['Three'].title}</h3>
						<span>{getCarousel['Three'].date}</span>
					</div>
				</div>

				<div>
					<div className="carousel--item__circle">
						<div className="carousel--item__img">
							<Image
								src={four}
								fallback={<Shimmer id="shimmer" width={407} height={407} />}
							/>
						</div>
						<div className="carousel--item__rating">
							<p>{getCarousel['Four'].rating}</p>
							<span>RATING</span>
						</div>
					</div>
					<div className="carousel--item__text">
						<h3>{getCarousel['Four'].title}</h3>
						<span>{getCarousel['Four'].date}</span>
					</div>
				</div>

				<div>
					<div className="carousel--item__circle">
						<div className="carousel--item__img">
							<Image
								src={five}
								fallback={<Shimmer id="shimmer" width={407} height={407} />}
							/>
						</div>
						<div className="carousel--item__rating">
							<p>{getCarousel['Five'].rating}</p>
							<span>RATING</span>
						</div>
					</div>
					<div className="carousel--item__text">
						<h3>{getCarousel['Five'].title}</h3>
						<span>{getCarousel['Five'].date}</span>
					</div>
				</div>

				<div>
					<div className="carousel--item__circle">
						<div className="carousel--item__img">
							<Image
								src={six}
								fallback={<Shimmer id="shimmer" width={407} height={407} />}
							/>
						</div>
						<div className="carousel--item__rating">
							<p>{getCarousel['Six'].rating}</p>
							<span>RATING</span>
						</div>
					</div>
					<div className="carousel--item__text">
						<h3>{getCarousel['Six'].title}</h3>
						<span>{getCarousel['Six'].date}</span>
					</div>
				</div>

			</Carousel>
		</Fragment>
	 );
}

export default CarouselSectionOne;