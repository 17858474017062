import React, { Fragment, useState, useEffect  } from 'react';

//Libraries
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useMediaQuery } from 'react-responsive';
import Aos from 'aos';
import 'aos/dist/aos.css';

//Layout

//Styles

//Images
import image00 from '../../../assets/imagenes/galeria/bucaramanga/bucaramanga01.jpg';
import image01 from '../../../assets/imagenes/galeria/bucaramanga/bucaramanga02.jpg';
import image02 from '../../../assets/imagenes/galeria/bucaramanga/bucaramanga03.jpg';
import image03 from '../../../assets/imagenes/galeria/bucaramanga/bucaramanga04.jpg';
import image04 from '../../../assets/imagenes/galeria/bucaramanga/bucaramanga05.jpg';
import image05 from '../../../assets/imagenes/galeria/bucaramanga/bucaramanga06.jpg';
import image06 from '../../../assets/imagenes/galeria/bucaramanga/bucaramanga07.jpg';

const images = [
  image00,
	image01,
	image02,
	image03,
	image04,
	image05,
	image06,
];

const Bucaramanga = () => {

	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	const [getPhotoIndex, setPhotoIndex] = useState(0);
	const [getIsOpen, setIsOpen] = useState(false);

	//Libraries-Carousel
	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 1
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1
		}
	};

	const tooglesGroupId = 'Toggles';
	const valuesGroupId = 'Values';
	const mainGroupId = 'Main';

	const getConfigurableProps = () => ({
		showArrows: true,
		showStatus: false,
		showIndicators: false,
		swipeable: true,
		showThumbs: false,
		centerSlidePercentage: 10,
		swipeScrollTolerance: 5,
		preventMovementUntilSwipeScrollTolerance: true,
	});

	return (
		<Fragment>
			<Carousel className="pictures" {...getConfigurableProps()}>
				<div className="pictures--item"  data-aos="fade-left">
					<div>
							<div className="pictures--item__img">
								<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(0)] }>
									<img src={image00} />
								</button>
							</div>
							<div className="pictures--item__img">
								<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(1)]}>
									<img src={image01} />
								</button>
							</div>
					</div>
				</div>
				<div className="pictures--item"  data-aos="fade-left">
					<div>
							<div className="pictures--item__img">
								<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(2)] }>
									<img src={image02} />
								</button>
							</div>
							<div className="pictures--item__img">
								<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(3)]}>
									<img src={image03} />
								</button>
							</div>
					</div>
				</div>
				<div className="pictures--item"  data-aos="fade-left">
					<div>
							<div className="pictures--item__img">
								<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(4)] }>
									<img src={image04} />
								</button>
							</div>
							<div className="pictures--item__img">
								<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(5)]}>
									<img src={image05} />
								</button>
							</div>
					</div>
				</div>
				<div className="pictures--item"  data-aos="fade-left">
					<div>
							<div className="pictures--item__img">
								<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(6)]}>
									<img src={image06} />
								</button>
							</div>
					</div>
				</div>
			</Carousel>

			{getIsOpen && (
          <Lightbox
            mainSrc={images[getPhotoIndex]}
            nextSrc={images[(getPhotoIndex + 1) % images.length]}
            prevSrc={images[(getPhotoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((getPhotoIndex+ images.length - 1) % images.length,)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((getPhotoIndex+ 1) % images.length,)
            }
						enableZoom={false}
          />
        )}
		</Fragment>
	 );
}

export default Bucaramanga;