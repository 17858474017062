import React, {Fragment, useEffect, useState} from 'react';

//Libraries
import { useMediaQuery } from 'react-responsive';
import Aos from 'aos';
import 'aos/dist/aos.css';

//Layout
import Juries from '../elements/cast/juries';
import Presenters from '../elements/cast/presenters';

//Styles
import { Cast } from '../../styles/gstyles';

//Images
import logo from '../../assets/imagenes/logo-index-big.png';

const SectionFive = ({getPassCurrentSection}) => {
	//State start section
	const [getStartAnimation, setStartAnimation] = useState(false);

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	//UseEffect animation
	useEffect(() => {
		if(getPassCurrentSection === '#five'){
			setStartAnimation(true);
		} else {
			setStartAnimation(false);
		}

		Aos.init({ duration: 2000 });

	}, [getPassCurrentSection])

	return (
		<Fragment>

			{/* // Cast Styles */}
			<Cast />

			{isTabletOrMobile &&
				<div className="cast">
					<div className="cast--logo" data-aos="fade-down">
						<img src={logo} />
					</div>
					<div className="cast--text" data-aos="fade-down">
						<h3>Ellos están listos para darle el sí</h3>
						<h4>a los dobles perfectos<br/>de esta temporada</h4>
					</div>
					<div className="cast--juries" data-aos="fade-down">
						<Juries getStartAnimation={getStartAnimation} />
					</div>
					<div className="cast--presenters" data-aos="fade-down">
						<Presenters getStartAnimation={getStartAnimation} />
					</div>
				</div>
			}

			{isDesktopOrLaptop &&
				<div className="cast" data-aos="zoom-in-down">
					<div className="cast--presenters">
						<Presenters getStartAnimation={getStartAnimation} />
					</div>
					<div className="cast--center">
						<div className="cast--logo">
							<img src={logo} />
							<h3>Ellos están listos para darle el sí</h3>
							<h4>a los dobles perfectos<br/>de esta temporada</h4>
						</div>
					</div>
					<div className="cast--juries">
						<Juries getStartAnimation={getStartAnimation} />
					</div>
				</div>
			}

			{isBigScreen &&
				<div className="cast">
					<div className="cast--presenters">
						<Presenters getStartAnimation={getStartAnimation} />
					</div>
					<div className="cast--center">
						<div className="cast--logo">
							<img src={logo} />
							<h3>Ellos están listos para darle el sí</h3>
							<h4>a los dobles perfectos<br/>de esta temporada</h4>
						</div>
					</div>
					<div className="cast--juries">
						<Juries getStartAnimation={getStartAnimation} />
					</div>
				</div>
			}
		</Fragment>
	 );
}

export default SectionFive;