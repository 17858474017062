// Reset
export const reset = {
	space :  "padding: 0; margin: 0;"
}

//Colors

export const colors = {
	white: '#FFFFFF' ,
	black: '#000000',
	yellow: '#ffc100',
	creamLight: '#fff4e2',
	creamRgb: '255,243,226',
	creamDark: '#f1d19e',
	dgray: '#212121',
	tgray: 'rgba(5, 6, 9, 0.8)',
	purple: '#9e36c0',
	brown: '#201a1c',
}

//Parameters

export const mixins ={
	box : 'box-sizing: border-box;',
	indicator : '3'
}

//Gap

export const gap = {
	gap5: `5px`,
	gap10: `10px`,
	gap15: `15px`,
	gap17: `17px`,
	gap20: `20px`,
	gap25: `25px`,
	gap30: `30px`,
	gap40: `40px`,
	gap50: `50px`,
	gap60: `60px`,
	gap70: `70px`,
	gap80: `80px`,
	gap90: `90px`,
	gap120: `120px`,
	gap130: `130px`,
	gap170: `170px`,
	gapPoints: `13.2px`
}

// Fonts

export const fonts = {
	font_00 : "font-family: 'Montserrat', sans-serif; font-weight: 700;",
	font_01 : "font-family: 'Montserrat', sans-serif; font-weight: normal;",
	font_normal : "font-stretch: normal; font-style: normal; line-height: normal; letter-spacing: normal;"
}

// Flex

export const flex = {
	center : "display: flex; align-items: center;",
	left : "display: flex; justify-content: flex-start;",
	right : "display: flex; justify-content: flex-end;",
	centerdc : "display: flex; flex-direction: column; justify-content: center;",
	centerdr : "display: flex; flex-direction: row; justify-content: space-between;",
	topdr : "display: flex; flex-direction: row; justify-content: top; align-items: top;"
}
