import React, { Fragment, useEffect } from 'react';

//Libraries
import { useMediaQuery } from 'react-responsive';
import Aos from 'aos';
import 'aos/dist/aos.css';

//Layout
import DesktopTimeline from '../elements/desktoptimeline';
import TimelineMobile from '../elements/timelinemobile';
import MobileTimeline from '../elements/mobiletimeline';

//Styles

//Images

const SectionThree = () => {

	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);


	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	return (
		<Fragment>
			{isTabletOrMobile &&
				<h2 id="three-anchor" data-aos="fade-down">Yo me llamo:<br /> a través de<br /> los años</h2>
			}

			{isDesktopOrLaptop &&
				<h2 id="three-anchor" data-aos="fade-down">Yo me llamo: a través de los años</h2>
			}

			{isBigScreen &&
				<h2 id="three-anchor" data-aos="fade-down">Yo me llamo: a través de los años</h2>
			}

			{isTabletOrMobile &&
				<TimelineMobile />
			}

			{isDesktopOrLaptop &&
				<DesktopTimeline />
			}

			{isBigScreen &&
				<DesktopTimeline />
			}
		</Fragment>
	 );
}

export default SectionThree;