import React, { Fragment, useState, useEffect } from 'react';

//Libraries
import Lottie from 'react-lottie-player'
import { useMediaQuery } from 'react-responsive';
import { gsap, TimelineLite, Power3 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ReactImageVideoLightbox from "react-image-video-lightbox";

//Layout
import Boton from './boton';
import Menu from './menu';

//Styles
import { HeaderStyles } from '../../styles/gstyles';

//Images
import {ReactComponent as Logo} from '../../assets/imagenes/logo-header.svg';
import Prev from  '../../assets/imagenes/prev.png';
import Next from '../../assets/imagenes/next.png';


const Header = ({setStart, setPosInitial, setPassCurrentSection}) => {
	//State Scroll
	const [getScrolling, setScrolling] = useState(false);
	const [getEventScrolling, setEventScrolling] = useState(false);
  const [getScrollTop, setScrollTop] = useState(0)
	const [getScrollUp, setScrollUp] = useState(0)

	//State Sections
	const [getHeightHome, setHeightHome] = useState(0);
	const [getOneTop, setOneTop] = useState();
	const [getSixTop, setSixTop] = useState();
	const [getDelay, setDelay] = useState(false);

	//State Href
	const [getArrayOfSecctions, setArrayOfSecctions] = useState({
		'#home' : { 'prev': '#home', 'next': '#one'},
		'#one' : { 'prev': '#home', 'next': '#two'},
		'#two' : { 'prev': '#one', 'next': '#three'},
		'#three' : { 'prev': '#two', 'next': '#four'},
		'#four' : { 'prev': '#three', 'next': '#five'},
		'#five' : { 'prev': '#four', 'next': '#six'},
		'#six' : { 'prev': '#home', 'next': 'last'}
	});

	const [getCurrentSection, setCurrentSection] = useState('#home');
	const [getPrevSection, setPrevSection] = useState('#home');
	const [getNextSection, setNextSection] = useState('#two');
	const [getHash, setHash] = useState('');

	//State Position
	const [getPosHeader, setPosHeader] = useState('0');
	const [getPosHome, setPosHome] = useState('0');
	const [getPosOne, setPosOne] = useState('0');
	const [getPosTwo, setPosTwo] = useState('0');
	const [getPosThree, setPosThree] = useState('0');
	const [getPosFour, setPosFour] = useState('0');
	const [getPosFive, setPosFive] = useState('0');
	const [getPosSix, setPosSix] = useState('0');

	//State Header
	const [getHeader, setHeader] = useState(false);
	const [getLastOne, setLastOne] = useState(false);

	//State Menu
	const [getMenuOpened, setMenuOpened] = useState(false);

	//State Ligthbox
	const [isOpen, setIsOpen] = React.useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

	const data = [
		{
			url: "https://www.youtube.com/embed/zIs1BVY6dpY",
			type: "video",
    	altTag: "some video"
		}
	];

	const functionSection = e => {
		const pathUrl = e.target.hash;
		setCurrentSection(pathUrl);
		setDelay(true);
		setTimeout(() => {
			setDelay(false);
			setPrevSection(getArrayOfSecctions[pathUrl].prev);
			setNextSection(getArrayOfSecctions[pathUrl].next);
			if(pathUrl === '#one') {
				const plusPos = getPosOne + 50;
				window.scrollTo( 0, plusPos );
			}
			if(pathUrl === '#two') {
				const plusPos = getPosTwo + 50;
				window.scrollTo( 0, plusPos );
			}
			if(pathUrl === '#three') {
				const plusPos = getPosThree + 50;
				window.scrollTo( 0, plusPos );
			}
			if(pathUrl === '#four') {
				const plusPos = getPosFour + 50;
				window.scrollTo( 0, plusPos );
			}
			if(pathUrl === '#five') {
				const plusPos = getPosFive + 50;
				window.scrollTo( 0, plusPos );
			}
			if(pathUrl === '#six') {
				const plusPos = getPosSix + 50;
				window.scrollTo( 0, plusPos );
			}
		}, 300);


	}

	//UseEffect Scroll
	useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > getScrollTop);
			setScrollUp(e.target.documentElement.scrollTop < getScrollTop);

			window.addEventListener('scroll', function() {
				setEventScrolling(true);
			});

			if ( getScrolling ) {
				setStart(true);

				setHeightHome(document.querySelector('#home').offsetHeight);

				setOneTop(document.querySelector('#one').offsetTop - (getHeightHome / 3 ));

				setSixTop(document.querySelector('#six').offsetTop - (getHeightHome / 3 ));
			}

			if ( getEventScrolling ) {
				setPosHome(document.querySelector('#home').offsetTop);
				setPosOne(document.querySelector('#one').offsetTop);
				setPosTwo(document.querySelector('#two').offsetTop);
				setPosThree(document.querySelector('#three').offsetTop);
				setPosFour(document.querySelector('#four').offsetTop);
				setPosFive(document.querySelector('#five').offsetTop);
				setPosSix(document.querySelector('#six').offsetTop);

				if(!getDelay){
					if( getScrollTop >= getPosHome && getScrollTop < getPosOne ) {
						setCurrentSection('#home');
						if(getCurrentSection !== '#home'){
							setPassCurrentSection('#home');
							setPrevSection(getArrayOfSecctions['#home'].prev);
							setNextSection(getArrayOfSecctions['#home'].next);
							animationHome();
							/* console.log('home'); */
						}
					} else if ( getScrollTop >= getPosOne && getScrollTop < getPosTwo ) {
						setCurrentSection('#one');
						if(getCurrentSection !== '#one'){
							setPassCurrentSection('#one');
							setPrevSection(getArrayOfSecctions['#one'].prev);
							setNextSection(getArrayOfSecctions['#one'].next);
							animationOne();
							/* console.log('one'); */
						}
					} else if ( getScrollTop >= getPosTwo && getScrollTop < getPosThree ) {
						setCurrentSection('#two');
						if(getCurrentSection !== '#two'){
							setPassCurrentSection('#two');
							setPrevSection(getArrayOfSecctions['#two'].prev);
							setNextSection(getArrayOfSecctions['#two'].next);
							animationTwo();
							/* console.log('two'); */
						}
					} else if ( getScrollTop >= getPosThree && getScrollTop < getPosFour ) {
						setCurrentSection('#three');
						if(getCurrentSection !== '#three'){
							setPassCurrentSection('#three');
							setPrevSection(getArrayOfSecctions['#three'].prev);
							setNextSection(getArrayOfSecctions['#three'].next);
							animationThree();
							/* console.log('three'); */
						}
					} else if ( getScrollTop >= getPosFour && getScrollTop < getPosFive ) {
						setCurrentSection('#four');
						if(getCurrentSection !== '#four'){
							setPassCurrentSection('#four');
							setPrevSection(getArrayOfSecctions['#four'].prev);
							setNextSection(getArrayOfSecctions['#four'].next);
							animationFour();
							/* console.log('four'); */
						}
					} else if ( getScrollTop >= getPosFive && getScrollTop < getPosSix ) {
						setCurrentSection('#five');
						if(getCurrentSection !== '#five'){
							setPassCurrentSection('#five');
							setPrevSection(getArrayOfSecctions['#five'].prev);
							setNextSection(getArrayOfSecctions['#five'].next);
							animationFive();
							/* console.log('five'); */
						}
					} else if ( getScrollTop >= getPosSix ) {
						setCurrentSection('#six');
						if(getCurrentSection !== '#six'){
							setPassCurrentSection('#six');
							setPrevSection(getArrayOfSecctions['#six'].prev);
							setNextSection(getArrayOfSecctions['#six'].next);
							animationSix();
							/* console.log('six'); */
						}
					}
				}
			}

			if (!getHeader) {
				if ( getScrollTop > getOneTop ) {
					setHeader(true);
				}
			}

			if (getHeader) {
				if ( getScrollTop < getOneTop ) {
					setHeader(false);
				}
			}

			if ( getScrollTop > getSixTop ) {
				setLastOne(true);
			} else {
				setLastOne(false);
			}
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);

  }, [getScrollTop, setScrollUp, getHeader]);

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	//Function Open Menu
	const functionOpenMenu = () => {
		if(getMenuOpened) {
			setMenuOpened(false);
		} else {
			setMenuOpened(true);
		}
	}

	//Functions Animations
	const animationHome = () => {

		//* HOME *//
		const onlyItemPrev = document.querySelector('#six');
		const onlyItemCurrently = document.querySelector('#home');
		const onlyItemNext = document.querySelector('#one');

		onlyItemPrev.classList.remove('animate');
		onlyItemNext.classList.remove('animate');
		onlyItemCurrently.classList.add('animate');

	}

	const animationOne = () => {

		//* ONE *//
		const onlyItemPrev = document.querySelector('#home');
		const onlyItemCurrently = document.querySelector('#one');
		const onlyItemNext = document.querySelector('#two');

		onlyItemPrev.classList.remove('animate');
		onlyItemNext.classList.remove('animate');
		onlyItemCurrently.classList.add('animate');

	}

	const animationTwo = () => {

		//* TWO *//
		const onlyItemPrev = document.querySelector('#one');
		const onlyItemCurrently = document.querySelector('#two');
		const onlyItemNext = document.querySelector('#three');

		onlyItemPrev.classList.remove('animate');
		onlyItemNext.classList.remove('animate');
		onlyItemCurrently.classList.add('animate');

	}

	const animationThree = () => {

		//* THREE *//
		const onlyItemPrev = document.querySelector('#two');
		const onlyItemCurrently = document.querySelector('#three');
		const onlyItemNext = document.querySelector('#four');

		onlyItemPrev.classList.remove('animate');
		onlyItemNext.classList.remove('animate');
		onlyItemCurrently.classList.add('animate');

	}

	const animationFour = () => {

		//* FOUR *//
		const onlyItemPrev = document.querySelector('#three');
		const onlyItemCurrently = document.querySelector('#four');
		const onlyItemNext = document.querySelector('#five');

		onlyItemPrev.classList.remove('animate');
		onlyItemNext.classList.remove('animate');
		onlyItemCurrently.classList.add('animate');

	}

	const animationFive = () => {

		//* FIVE *//
		const onlyItemPrev = document.querySelector('#four');
		const onlyItemCurrently = document.querySelector('#five');
		const onlyItemNext = document.querySelector('#six');

		onlyItemPrev.classList.remove('animate');
		onlyItemNext.classList.remove('animate');
		onlyItemCurrently.classList.add('animate');

	}

	const animationSix = () => {

		//* SIX *//
		const onlyItemPrev = document.querySelector('#five');
		const onlyItemCurrently = document.querySelector('#six');
		const onlyItemNext = document.querySelector('#home');

		onlyItemPrev.classList.remove('animate');
		onlyItemNext.classList.remove('animate');
		onlyItemCurrently.classList.add('animate');

	}


	return (
		<Fragment>

			{/* // Header Styles */}
			<HeaderStyles />

			<header className={ getScrollTop < 100 ? "header disappear" : "header appear" }>

			<div className="header__logo">
				<Logo />
			</div>

			{isTabletOrMobile &&
				<div className="header__menubutton header__menubutton_buttonsize" >
					<a onClick={() => functionOpenMenu() }><Boton passToggleButton={ getMenuOpened ? false : true } /></a>
				</div>
			}

			{isDesktopOrLaptop &&
				<>
					<div className={ getCurrentSection === '#six' ||  getCurrentSection === '#six' ? "header__asidebar last" : "header__asidebar"}>
						<div className={ getCurrentSection === '#six' ||  getCurrentSection === '#six' ? 'header__asidebar--menu disappear' : 'header__asidebar--menu appear'}>
							<ul>
								<li className={ getScrollTop >= getPosOne && getScrollTop < getPosTwo ? 'active' : 'inactive' }><a className="anchor" href='#one' onClick={(e) => functionSection(e)}>01</a></li>
								<li className={ getScrollTop >= getPosTwo && getScrollTop < getPosThree ? 'active' : 'inactive' }><a className="anchor" href='#two' onClick={(e) => functionSection(e)}>02</a></li>
								<li className={ getScrollTop >= getPosThree && getScrollTop < getPosFour ? 'active' : 'inactive' }><a className="anchor" href='#three' onClick={(e) => functionSection(e)}>03</a></li>
								<li className={ getScrollTop >= getPosFour && getScrollTop < getPosFive? 'active' : 'inactive' }><a className="anchor" href='#four' onClick={(e) => functionSection(e)}>04</a></li>
								<li className={ getScrollTop >= getPosFive && getScrollTop < getPosSix ? 'active' : 'inactive' }><a className="anchor" href="#five" onClick={(e) => functionSection(e)}>05</a></li>
							</ul>
						</div>
					</div>

					<a className="btn-video anchor" href="#two" onClick={() => handleToggle()}>Video</a>
				</>
			}

			</header>

			{ isDesktopOrLaptop &&
				<div className={ getScrollTop >= getPosHome && getScrollTop < getPosOne  ||  getCurrentSection === '#home' ? "right-buttons disappear" : "right-buttons appear"}>


					<a href={getPrevSection} className="anchor appear" onClick={(e) => functionSection(e)} id="prev">
						<img src={Prev}></img>
						{/* <p>{getPrevSection}</p> */}
					</a>

					{getNextSection !== 'last' ?
						<a href={getNextSection} className={ getNextSection !== 'last' ? 'anchor appear' : 'anchor disappear' } onClick={(e) => functionSection(e)} id="next">
							<img src={Prev}></img>
							{/* <p>{getNextSection}</p> */}
						</a>
					: null }
			</div>
			}

			{ getMenuOpened && getScrollTop > 100 ?
				<nav className="menu">
					<Menu getScrollTop={getScrollTop} getPosOne={getPosOne} getPosTwo={getPosTwo} getPosThree={getPosThree} getPosFour={getPosFour} getPosFive={getPosFive} getPosSix={getPosSix} functionSection={functionSection} setMenuOpened={setMenuOpened}/>
				</nav>
			: null }

			{isOpen ? (
				<div className="ligthbox-video" id="ligthbox-video">
        	<ReactImageVideoLightbox
          	data={data}
          	startIndex={0}
          	showResourceCount={false}
          	onCloseCallback={() => handleToggle()}
        	/>
				</div>
      ) : null}

		</Fragment>
	 );
}

export default Header;