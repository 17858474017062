import React, { Fragment, useState, useEffect } from 'react';

//Libraries
import Lottie from 'react-lottie-player';
import { useMediaQuery } from 'react-responsive'

//Animations
import JuriesAnimation from '../../../assets/animaciones/juries-mobile.json';
import JuriesoAnimationBig from '../../../assets/animaciones/juries-desktop.json';

const Juries = ({getStartAnimation}) => {


	//State Animations
	const [toggleButton, setToggleButton] = useState(false);
	const [segmentFrom, setSegmentFrom] = useState(0);
  const [segmentTo, setSegmentTo] = useState(0);
  const [segmentsEnabled, setSegmentsEnabled] = useState(false);
  const [play, setPlay] = useState(false);
  const [loop, setLoop] = useState(false);
  const [loopTimes, setLoopTimes] = useState(0);
  const [speed, setSpeed] = useState(5);
  const [direction, setDirection] = useState(1);
  const segments = [segmentFrom, segmentTo];

	const [log, setLog] = useState([]);
  const addLog = (v) => setLog(l => [v, ...l]);

	//UseEffect animation
	useEffect(() => {
		if(!play){
			if(getStartAnimation) {
				setPlay(true);
				setSegmentsEnabled(true);
				setSegmentFrom(0);
				setSegmentTo(300);
			}else{
				setPlay(false);
			}
		}
	}, [getStartAnimation])

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	return (
		<Fragment>
			{isTabletOrMobile &&
				<Lottie
					loop={loop}
					speed={speed}
					play={play}
					animationData={JuriesAnimation}
					direction={direction}
					segments={segmentsEnabled && segments}
					style={{ width: 300, marginBottom: 0, alignSelf: 'center', cursor: 'pointer' }}
					onComplete={() => addLog('complete')}
					onLoopComplete={() => addLog('loopComplete')}
					onEnterFrame={() => { /* addLog('enterFrame') */ }}
					onSegmentStart={() => addLog('segmentStart')}
					onLoad={() => addLog('load')}
				/>
			}
			{isDesktopOrLaptop &&
				<Lottie
					loop={loop}
					speed={speed}
					play={play}
					animationData={JuriesoAnimationBig}
					direction={direction}
					segments={segmentsEnabled && segments}
					style={{ width: 342, height: 610, marginBottom: 0, alignSelf: 'center', cursor: 'pointer' }}
					onComplete={() => addLog('complete')}
					onLoopComplete={() => addLog('loopComplete')}
					onEnterFrame={() => { /* addLog('enterFrame') */ }}
					onSegmentStart={() => addLog('segmentStart')}
					onLoad={() => addLog('load')}
				/>
			}
			{isBigScreen &&
				<Lottie
					loop={loop}
					speed={speed}
					play={play}
					animationData={JuriesoAnimationBig}
					direction={direction}
					segments={segmentsEnabled && segments}
					style={{ width: 685, height: 1220, marginBottom: 0, alignSelf: 'center', cursor: 'pointer' }}
					onComplete={() => addLog('complete')}
					onLoopComplete={() => addLog('loopComplete')}
					onEnterFrame={() => { /* addLog('enterFrame') */ }}
					onSegmentStart={() => addLog('segmentStart')}
					onLoad={() => addLog('load')}
				/>
			}
		</Fragment>
	 );
}

export default Juries;