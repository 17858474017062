import React, { Fragment, useState, useEffect } from 'react';

//Data
import timelinedata from '../../assets/data/timelineData';

//Libraries
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Aos from 'aos';
import 'aos/dist/aos.css';

//Layout

//Styles
import { TimelineStylesMobile } from '../../styles/gstyles';

//Images
import ganadorone from '../../assets/imagenes/timeline/ganador_one_01.jpg';
import ganadortwo from '../../assets/imagenes/timeline/ganador_two_01.jpg';
import ganadorthreeone from '../../assets/imagenes/timeline/ganador_three_01.jpg';
import ganadorthreetwo from '../../assets/imagenes/timeline/ganador_three_02.jpg';
import ganadorfour from '../../assets/imagenes/timeline/ganador_four_01.jpg';
import ganadorfive from '../../assets/imagenes/timeline/ganador_five_01.jpg';
import ganadorsix from '../../assets/imagenes/timeline/ganador_six_01.jpg';
import ganadorseven from '../../assets/imagenes/timeline/ganador_seven_01.jpg';
// One
import presentadorlindaone from '../../assets/imagenes/timeline/presentador_linda_01.jpg';
import presentadorernestoone from '../../assets/imagenes/timeline/presentador_ernesto_01.jpg';
import juradoamparoone from '../../assets/imagenes/timeline/jurado_amparo_01.jpg';
import juradoluzone from '../../assets/imagenes/timeline/jurado_luz_01.jpg';
import juradojairoone from '../../assets/imagenes/timeline/jurado_jairo_01.jpg';
// Two
import presentadorlindatwo from '../../assets/imagenes/timeline/presentador_linda_01.jpg';
import presentadorernestotwo from '../../assets/imagenes/timeline/presentador_ernesto_01.jpg';
import juradoamparotwo from '../../assets/imagenes/timeline/jurado_amparo_01.jpg';
import juradoluztwo from '../../assets/imagenes/timeline/jurado_luz_01.jpg';
import juradojairotwo from '../../assets/imagenes/timeline/jurado_jairo_01.jpg';
// Three
import presentadorsandrathree from '../../assets/imagenes/timeline/presentador_sandra_01.jpg';
import presentadorernestothree from '../../assets/imagenes/timeline/presentador_ernesto_02.jpg';
import juradoamparothree from '../../assets/imagenes/timeline/jurado_amparo_01.jpg';
import juradoluzthree from '../../assets/imagenes/timeline/jurado_luz_02.jpg';
import juradojairothree from '../../assets/imagenes/timeline/jurado_jairo_02.jpg';
// Four
import presentadorsandrafour from '../../assets/imagenes/timeline/presentador_sandra_02.jpg';
import presentadorernestofour from '../../assets/imagenes/timeline/presentador_ernesto_03.jpg';
import juradobellkisfour from '../../assets/imagenes/timeline/jurado_bellkis_01.jpg';
import juradoluzfour from '../../assets/imagenes/timeline/jurado_luz_03.jpg';
import juradojuliofour from '../../assets/imagenes/timeline/jurado_julio_01.jpg';
// Five
import presentadorjesicafive from '../../assets/imagenes/timeline/presentador_jesica_01.jpg';
import presentadorernestofive from '../../assets/imagenes/timeline/presentador_ernesto_04.jpg';
import juradoamparofive from '../../assets/imagenes/timeline/jurado_amparo_02.jpg';
import juradoescolafive from '../../assets/imagenes/timeline/jurado_escola_01.jpg';
import juradopipefive from '../../assets/imagenes/timeline/jurado_pipe_01.jpg';
// Six
import presentadormelinasix from '../../assets/imagenes/timeline/presentador_melina_01.jpg';
import presentadorernestosix from '../../assets/imagenes/timeline/presentador_ernesto_04.jpg';
import juradoamparosix from '../../assets/imagenes/timeline/jurado_amparo_03.jpg';
import juradoescolasix from '../../assets/imagenes/timeline/jurado_escola_02.jpg';
import juradopipesix from '../../assets/imagenes/timeline/jurado_pipe_02.jpg';
// Seven
import presentadorvaleriseven from '../../assets/imagenes/timeline/presentador_valerie_01.jpg';
import presentadorcaleroseven from '../../assets/imagenes/timeline/presentador_calero_01.jpg';
import juradoamparoseven from '../../assets/imagenes/timeline/jurado_amparo_04.jpg';
import juradoescolaseven from '../../assets/imagenes/timeline/jurado_escola_03.jpg';
import juradouribeseven from '../../assets/imagenes/timeline/jurado_uribe_01.jpg';

const TimelineMobile = () => {

	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);

	const tooglesGroupId = 'Toggles';
	const valuesGroupId = 'Values';
	const mainGroupId = 'Main';

	const getConfigurableProps = () => ({
		showArrows: true,
		showStatus: false,
		showIndicators: false,
		swipeable: true,
		showThumbs: false,
		centerSlidePercentage: 10,
		swipeScrollTolerance: 5,
		preventMovementUntilSwipeScrollTolerance: true,
	});

	//State of data
	const [getTimeline, setTimeline] = useState(timelinedata);

	return (
		<Fragment>

			{/* // TimelineStylesMobile Styles */}
			<TimelineStylesMobile />

				<Carousel className="timeline" {...getConfigurableProps()}>
					<div className="timeline--item" data-aos="fade-left">
						<div>
							<h3>{getTimeline['One'].title}</h3>
							<p><span>Lanzamiento</span> {getTimeline['One'].date}</p>
							<div className="timeline--item__winner">
								<div className="timeline--item__circle">
									<div className="timeline--item__img">
										<img src={ganadorone} />
									</div>
								</div>
								<div className="timeline--item__text">
									<h4>{getTimeline['One'].ganadorone}</h4>
									<span>Ganador</span>
									<p>Interpretó a {getTimeline['One'].interpretoone}</p>
								</div>
							</div>
							<div className="timeline--item__cast">
								<div className="timeline--item__presenters">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorlindaone} />
											</div>
											<p>{getTimeline['One'].presentadorone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorernestoone} />
											</div>
											<p>{getTimeline['One'].presentadortwo}</p>
										</li>
									</ul>
									<h4>Presentadores</h4>
								</div>
								<div className="timeline--item__juries">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={juradoamparoone} />
											</div>
											<p>{getTimeline['One'].juradoone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradoluzone} />
											</div>
											<p>{getTimeline['One'].juradotwo}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradojairoone} />
											</div>
											<p>{getTimeline['One'].juradothree}</p>
										</li>
									</ul>
									<h4>Jurados</h4>
								</div>
							</div>
						</div>
					</div>
					<div className="timeline--item"  data-aos="fade-left">
						<div>
						<h3>{getTimeline['Two'].title}</h3>
							<p><span>Lanzamiento</span> {getTimeline['Two'].date}</p>
							<div className="timeline--item__winner">
								<div className="timeline--item__circle">
									<div className="timeline--item__img">
										<img src={ganadortwo} />
									</div>
								</div>
								<div className="timeline--item__text">
									<h4>{getTimeline['Two'].ganadorone}</h4>
									<span>Ganador</span>
									<p>Interpretó a {getTimeline['Two'].interpretoone}</p>
								</div>
							</div>
							<div className="timeline--item__cast">
								<div className="timeline--item__presenters">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorlindatwo} />
											</div>
											<p>{getTimeline['Two'].presentadorone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorernestotwo} />
											</div>
											<p>{getTimeline['Two'].presentadortwo}</p>
										</li>
									</ul>
									<h4>Presentadores</h4>
								</div>
								<div className="timeline--item__juries">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={juradoamparotwo} />
											</div>
											<p>{getTimeline['Two'].juradoone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradoluztwo} />
											</div>
											<p>{getTimeline['Two'].juradotwo}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradojairotwo} />
											</div>
											<p>{getTimeline['Two'].juradothree}</p>
										</li>
									</ul>
									<h4>Jurados</h4>
								</div>
							</div>
						</div>
					</div>
					<div className="timeline--item"  data-aos="fade-left">
						<div>
						<h3>{getTimeline['Three'].title}</h3>
							<p><span>Lanzamiento</span> {getTimeline['Three'].date}</p>
							<div className="timeline--item__winner">
								<div className="timeline--item__circle">
									<div className="timeline--item__img">
										<img src={ganadorthreeone} />
									</div>
								</div>
								<div className="timeline--item__text">
									<h4>{getTimeline['Three'].ganadorone}</h4>
									<span>Ganador</span>
									<p>Interpretó a {getTimeline['Three'].interpretoone}</p>
								</div>
							</div>
							<div className="timeline--item__winner">
								<div className="timeline--item__circle">
									<div className="timeline--item__img">
										<img src={ganadorthreetwo} />
									</div>
								</div>
								<div className="timeline--item__text">
									<h4>{getTimeline['Three'].ganadortwo}</h4>
									<span>Ganador</span>
									<p>Interpretó a {getTimeline['Three'].interpretotwo}</p>
								</div>
							</div>
							<div className="timeline--item__cast">
								<div className="timeline--item__presenters">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorsandrathree} />
											</div>
											<p>{getTimeline['Three'].presentadorone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorernestothree} />
											</div>
											<p>{getTimeline['Three'].presentadortwo}</p>
										</li>
									</ul>
									<h4>Presentadores</h4>
								</div>
								<div className="timeline--item__juries">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={juradoamparothree} />
											</div>
											<p>{getTimeline['Three'].juradoone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradoluzthree} />
											</div>
											<p>{getTimeline['Three'].juradotwo}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradojairothree} />
											</div>
											<p>{getTimeline['Three'].juradothree}</p>
										</li>
									</ul>
									<h4>Jurados</h4>
								</div>
							</div>
						</div>
					</div>
					<div className="timeline--item"  data-aos="fade-left">
						<div>
							<h3>{getTimeline['Four'].title}</h3>
							<p><span>Lanzamiento</span> {getTimeline['Four'].date}</p>
							<div className="timeline--item__winner">
								<div className="timeline--item__circle">
									<div className="timeline--item__img">
										<img src={ganadorfour} />
									</div>
								</div>
								<div className="timeline--item__text">
									<h4>{getTimeline['Four'].ganadorone}</h4>
									<span>Ganador</span>
									<p>Interpretó a {getTimeline['Four'].interpretoone}</p>
								</div>
							</div>
							<div className="timeline--item__cast">
								<div className="timeline--item__presenters">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorsandrafour} />
											</div>
											<p>{getTimeline['Four'].presentadorone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorernestofour} />
											</div>
											<p>{getTimeline['Four'].presentadortwo}</p>
										</li>
									</ul>
									<h4>Presentadores</h4>
								</div>
								<div className="timeline--item__juries">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={juradobellkisfour} />
											</div>
											<p>{getTimeline['Four'].juradoone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradoluzfour} />
											</div>
											<p>{getTimeline['Four'].juradotwo}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradojuliofour} />
											</div>
											<p>{getTimeline['Four'].juradothree}</p>
										</li>
									</ul>
									<h4>Jurados</h4>
								</div>
							</div>
						</div>
					</div>
					<div className="timeline--item"  data-aos="fade-left">
						<div>
							<h3>{getTimeline['Five'].title}</h3>
							<p><span>Lanzamiento</span> {getTimeline['Five'].date}</p>
							<div className="timeline--item__winner">
								<div className="timeline--item__circle">
									<div className="timeline--item__img">
										<img src={ganadorfive} />
									</div>
								</div>
								<div className="timeline--item__text">
									<h4>{getTimeline['Five'].ganadorone}</h4>
									<span>Ganador</span>
									<p>Interpretó a {getTimeline['Five'].interpretoone}</p>
								</div>
							</div>
							<div className="timeline--item__cast">
								<div className="timeline--item__presenters">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorjesicafive} />
											</div>
											<p>{getTimeline['Five'].presentadorone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorernestofive} />
											</div>
											<p>{getTimeline['Five'].presentadortwo}</p>
										</li>
									</ul>
									<h4>Presentadores</h4>
								</div>
								<div className="timeline--item__juries">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={juradoamparofive} />
											</div>
											<p>{getTimeline['Five'].juradoone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradoescolafive} />
											</div>
											<p>{getTimeline['Five'].juradothree}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradopipefive} />
											</div>
											<p>{getTimeline['Five'].juradotwo}</p>
										</li>
									</ul>
									<h4>Jurados</h4>
								</div>
							</div>
						</div>
					</div>
					<div className="timeline--item"  data-aos="fade-left">
						<div>
							<h3>{getTimeline['Six'].title}</h3>
							<p><span>Lanzamiento</span> {getTimeline['Six'].date}</p>
							<div className="timeline--item__winner">
								<div className="timeline--item__circle">
									<div className="timeline--item__img">
										<img src={ganadorsix} />
									</div>
								</div>
								<div className="timeline--item__text">
									<h4>{getTimeline['Six'].ganadorone}</h4>
									<span>Ganador</span>
									<p>Interpretó a {getTimeline['Six'].interpretoone}</p>
								</div>
							</div>
							<div className="timeline--item__cast">
								<div className="timeline--item__presenters">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={presentadormelinasix} />
											</div>
											<p>{getTimeline['Six'].presentadorone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorernestosix} />
											</div>
											<p>{getTimeline['Six'].presentadortwo}</p>
										</li>
									</ul>
									<h4>Presentadores</h4>
								</div>
								<div className="timeline--item__juries">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={juradoamparosix} />
											</div>
											<p>{getTimeline['Six'].juradoone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradoescolasix} />
											</div>
											<p>{getTimeline['Six'].juradotwo}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradopipesix} />
											</div>
											<p>{getTimeline['Six'].juradothree}</p>
										</li>
									</ul>
									<h4>Jurados</h4>
								</div>
							</div>
						</div>
					</div>
					<div className="timeline--item"  data-aos="fade-left">
						<div>
							<h3>{getTimeline['Seven'].title}</h3>
							<p><span>Lanzamiento</span> {getTimeline['Seven'].date}</p>
							<div className="timeline--item__winner">
								<div className="timeline--item__circle">
									<div className="timeline--item__img">
										<img src={ganadorseven} />
									</div>
								</div>
								<div className="timeline--item__text">
									<h4>{getTimeline['Seven'].ganadorone}</h4>
									<span>Ganador</span>
									<p>Interpretó a {getTimeline['Seven'].interpretoone}</p>
								</div>
							</div>
							<div className="timeline--item__cast">
								<div className="timeline--item__presenters">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorvaleriseven} />
											</div>
											<p>{getTimeline['Seven'].presentadorone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={presentadorcaleroseven} />
											</div>
											<p>{getTimeline['Seven'].presentadortwo}</p>
										</li>
									</ul>
									<h4>Presentadores</h4>
								</div>
								<div className="timeline--item__juries">
									<ul>
										<li>
											<div className="timeline--item__image">
												<img src={juradoamparoseven} />
											</div>
											<p>{getTimeline['Seven'].juradoone}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradoescolaseven} />
											</div>
											<p>{getTimeline['Seven'].juradotwo}</p>
										</li>
										<li>
											<div className="timeline--item__image">
												<img src={juradouribeseven} />
											</div>
											<p>{getTimeline['Seven'].juradothree}</p>
										</li>
									</ul>
									<h4>Jurados</h4>
								</div>
							</div>
						</div>
					</div>
				</Carousel>

		</Fragment>
	 );
}

export default TimelineMobile;