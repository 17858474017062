import React, { Fragment, useState } from 'react';

//Libraries
import Lottie from 'react-lottie-player';
import { useMediaQuery } from 'react-responsive';

//Animations
import MobileMenuMouseAnimation from '../../assets/animaciones/mouse-mobile.json';
import DesktopMenuMouseAnimation from '../../assets/animaciones/mouse-desktop.json';


const Mouse = () => {

	//State Animations
	const [toggleButton, setToggleButton] = useState(false);
	const [segmentFrom, setSegmentFrom] = useState(0);
	const [segmentTo, setSegmentTo] = useState(10);
	const [segmentsEnabled, setSegmentsEnabled] = useState(false);
	const [play, setPlay] = useState(true);
	const [loop, setLoop] = useState(true);
	const [loopTimes, setLoopTimes] = useState(0);
	const [speed, setSpeed] = useState(3);
	const [direction, setDirection] = useState(1);
	const segments = [segmentFrom, segmentTo];

	const [log, setLog] = useState([]);
	const addLog = (v) => setLog(l => [v, ...l]);

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1023px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1224px) and (max-width: 1439px)'
	})
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
	const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
	const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	return (
		<Fragment>
			{isTabletOrMobile &&
				<Lottie
						loop={loop}
						speed={speed}
						play={play}
						animationData={MobileMenuMouseAnimation}
						direction={direction}
						segments={false}
						style={{ width: 22, height: 36, marginBottom: 10, alignSelf: 'center', cursor: 'pointer' }}
						onComplete={() => addLog('complete')}
						onLoopComplete={() => addLog('loopComplete')}
						onEnterFrame={() => { /* addLog('enterFrame') */ }}
						onSegmentStart={() => addLog('segmentStart')}
						onLoad={() => addLog('load')}
				/>
			}
			{isDesktopOrLaptop &&
				<Lottie
						loop={loop}
						speed={speed}
						play={play}
						animationData={DesktopMenuMouseAnimation}
						direction={direction}
						segments={false}
						style={{ width: 25, height: 55, marginBottom: 7, alignSelf: 'center', cursor: 'pointer' }}
						onComplete={() => addLog('complete')}
						onLoopComplete={() => addLog('loopComplete')}
						onEnterFrame={() => { /* addLog('enterFrame') */ }}
						onSegmentStart={() => addLog('segmentStart')}
						onLoad={() => addLog('load')}
				/>
			}
			{isBigScreen &&
				<Lottie
						loop={loop}
						speed={speed}
						play={play}
						animationData={DesktopMenuMouseAnimation}
						direction={direction}
						segments={false}
						style={{ width: 39, height:70, marginBottom: 15, alignSelf: 'center', cursor: 'pointer' }}
						onComplete={() => addLog('complete')}
						onLoopComplete={() => addLog('loopComplete')}
						onEnterFrame={() => { /* addLog('enterFrame') */ }}
						onSegmentStart={() => addLog('segmentStart')}
						onLoad={() => addLog('load')}
				/>
			}
		</Fragment>
	 );
}

export default Mouse;