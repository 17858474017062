export const timelinedata = {
  One: {
		dobleganador: false,
    title: 'Yo me llamo 1',
    date: '22 de agosto de 2011',
    ganadorone: 'Jorge Martínez',
		interpretoone: 'Rafael Orozco',
		ganadortwo: '',
		interpretotwo: '',
		presentadorone: 'Linda Palma',
		presentadortwo: 'Ernesto Calzadilla',
		juradoone: 'Amparo Grisales',
		juradotwo: 'Luz Amparo Álvarez',
		juradothree: 'Jairo Martínez',
  },
  Two: {
		dobleganador: false,
    title: 'Yo me llamo 2',
    date: '13 de febrero de 2012',
    ganadorone: 'Brayan Fanier Álvarez',
		interpretoone: 'José José',
		ganadortwo: '',
		interpretotwo: '',
		presentadorone: 'Linda Palma',
		presentadortwo: 'Ernesto Calzadilla',
		juradoone: 'Amparo Grisales',
		juradotwo: 'Luz Amparo Álvarez',
		juradothree: 'Jairo Martínez',
  },
  Three: {
		dobleganador: true,
    title: 'Yo me llamo 3',
    date: '10 de febrero de 2014',
    ganadorone: 'Deybis Pino',
		interpretoone: 'Vicente Fernández',
		ganadortwo: 'Daniel Mora',
		interpretotwo: 'Sandro de América',
		presentadorone: 'Sandra Posada',
		presentadortwo: 'Ernesto Calzadilla',
		juradoone: 'Amparo Grisales',
		juradotwo: 'Luz Amparo Álvarez',
		juradothree: 'Jairo Martínez',
  },
  Four: {
		dobleganador: false,
    title: 'Yo me llamo, la conquista de América',
    date: '26 de enero de 2015',
    ganadorone: 'Brayan Fanier Álvarez',
		interpretoone: 'José José',
		ganadortwo: '',
		interpretotwo: '',
		presentadorone: 'Sandra Posada',
		presentadortwo: 'Ernesto Calzadilla',
		juradoone: 'Belkis Martínez',
		juradotwo: 'Luz Amparo Álvarez',
		juradothree: 'Julio Sabala',
  },
  Five: {
		dobleganador: false,
    title: 'Yo me llamo 5',
    date: '13 de febrero de 2017',
    ganadorone: 'David Alsina',
		interpretoone: 'Nicky Jam',
		ganadortwo: '',
		interpretotwo: '',
		presentadorone: 'Jessica Cediel',
		presentadortwo: 'Ernesto Calzadilla',
		juradoone: 'Amparo Grisales',
		juradotwo: 'Pipe Bueno',
		juradothree: 'César Escola',
  },
  Six: {
		dobleganador: false,
    title: 'Yo me llamo, un viaje musical',
    date: '18 de septiembre de 2018',
    ganadorone: 'Robinson Silva',
		interpretoone: 'Julio Jaramillo',
		ganadortwo: '',
		interpretotwo: '',
		presentadorone: 'Melina Ramírez',
		presentadortwo: 'Ernesto Calzadilla',
		juradoone: 'Amparo Grisales',
		juradotwo: 'César Escola',
		juradothree: 'Pipe Bueno',
  },
  Seven: {
		dobleganador: false,
    title: 'Yo me llamo 7',
    date: '24 de septiembre de 2019',
    ganadorone: 'Albert Sánchez',
		interpretoone: 'Roberto Carlos',
		ganadortwo: '',
		interpretotwo: '',
		presentadorone: 'Valerie Domínguez',
		presentadortwo: 'Carlos Calero',
		juradoone: 'Amparo Grisales',
		juradotwo: 'César Escola',
		juradothree: 'Jessi Uribe',
  },
}
export default timelinedata;