export const carouseldata = {
  One: {
    title: 'Yo me llamo 1',
    date: 'Agosto 22 de 2011',
    rating: '17.0',
    img: 'bg_DK_fake.jpg',
  },
  Two: {
    title: 'Yo me llamo 2',
    date: 'Febrero 13 de 2012',
    rating: '13,7',
    img: 'one',
  },
  Three: {
    title: 'Yo me llamo 3',
    date: 'Febrero 10 de 2014',
    rating: '11,6',
    img: 'two',
  },
  Four: {
    title: 'Yo me llamo, \n la conquista de América',
    date: 'Enero 26 de 2015',
    rating: '9,7',
    img: 'three',
  },
  Five: {
    title: 'Yo me llamo 5',
    date: 'Febrero 13 de 2017',
    rating: '14,6',
    img: 'four',
  },
  Six: {
    title: 'Yo me llamo, \n un viaje musical',
    date: 'Septiembre 18 de 2018',
    rating: '16,0',
    img: 'five',
  },
  Seven: {
    title: 'Yo me llamo 7',
    date: 'Septiembre 24 de 2019',
    rating: '15,1',
    img: 'six',
  },
}
export default carouseldata;