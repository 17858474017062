import React, { Fragment, useState, useEffect } from 'react';

//Libraries
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Aos from 'aos';
import 'aos/dist/aos.css';

//Layout

//Styles
import { GalleryDesktopStyles } from '../../styles/gstyles';
import Bogota from './ciudadesdesktop/bogota';
import Medellin from './ciudadesdesktop/medellin';
import Barranquilla from './ciudadesdesktop/barranquilla';
import Bucaramanga from './ciudadesdesktop/bucaramanga';

//Images

const GalleryDesktop = () => {

	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);

	const [tabIndex, setTabIndex] = useState(0);

	return (
		<Fragment>

			{/* // GalleryDesktop Styles */}
			<GalleryDesktopStyles />

			<div className="gallery" data-aos="fade-down">
				<Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
					<div className="gallery--list__content">
						<TabList className="gallery--list">
							<Tab className="gallery--list__item">Bogotá</Tab>
							<Tab className="gallery--list__item">Medellín</Tab>
							<Tab className="gallery--list__item">Barranquílla</Tab>
							<Tab className="gallery--list__item">Bucaramanga</Tab>
						</TabList>
					</div>

				<div className="gallery--content" data-aos="fade-up">
					<TabPanel className="gallery--content__item">
						<Bogota />
					</TabPanel>
					<TabPanel className="gallery--content__item">
						<Medellin />
					</TabPanel>
					<TabPanel className="gallery--content__item">
						<Barranquilla />
					</TabPanel>
					<TabPanel className="gallery--content__item">
						<Bucaramanga />
					</TabPanel>
				</div>
				</Tabs>
			</div>
		</Fragment>
	 );
}

export default GalleryDesktop;