import React, { Fragment } from 'react';

//Libraries
import { useMediaQuery } from 'react-responsive';

//Layout
import Back from '../layout/back';

//Styles
import { MenuStyles } from '../../styles/gstyles';

//Images


const Menu = ({getScrollTop, getPosOne, getPosTwo, getPosThree, getPosFour, getPosFive, getPosSix, functionSection, setMenuOpened}) => {

		//Const Mediaqueries
		const isTabletOrMobile = useMediaQuery({
			query: '(min-width: 350px) and (max-width: 1024px)'
		})
		const isDesktopOrLaptop = useMediaQuery({
			query: '(min-width: 1224px)'
		})
		const isBigScreen = useMediaQuery({
			query: '(min-width: 1440px)'
		})
		const isPortrait = useMediaQuery({
			query: '(orientation: portrait)'
		})
		const isRetina = useMediaQuery({
			query: '(min-resolution: 2dppx)'
		})

	return (
		<Fragment>

			{/* // Footer Styles */}
			<MenuStyles />

			<div className={ isPortrait ? 'menu portrait' : 'menu landscape' }>

				<div className="menu__back">
					{/* <Back /> */}
				</div>

				<ul className="menu__list">
					<li className={ getScrollTop >= getPosOne && getScrollTop < getPosTwo ? 'active' : 'inactive' }><span>01</span><a href="#one" className="anchor" onClick={(e) => [functionSection(e), setMenuOpened(false)]}>Un Formato<br />exitoso</a></li>

					<li className={ getScrollTop >= getPosTwo && getScrollTop < getPosThree ? 'active' : 'inactive' }><span>02</span><a href="#two" className="anchor" onClick={(e) => [functionSection(e), setMenuOpened(false)]}>La voz de sus<br />protagonistas</a></li>

					<li className={ getScrollTop >= getPosThree && getScrollTop < getPosFour ? 'active' : 'inactive' }><span>03</span><a href="#three" className="anchor" onClick={(e) => [functionSection(e), setMenuOpened(false)]}>A través de<br />los años</a></li>

					<li className={ getScrollTop >= getPosFour && getScrollTop < getPosFive? 'active' : 'inactive' }><span>04</span><a href="#four" className="anchor" onClick={(e) => [functionSection(e), setMenuOpened(false)]}>Conquistando<br />territorios</a></li>

					<li className={ getScrollTop >= getPosFive && getScrollTop < getPosSix ? 'active' : 'inactive' }><span>05</span><a href="#five" className="anchor" onClick={(e) => [functionSection(e), setMenuOpened(false)]}>Yo me llamo:<br />2021</a></li>
				</ul>
			</div>
		</Fragment>
	 );
}

export default Menu;