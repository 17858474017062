import React, { Fragment, useState } from 'react';

//Libraries
import Lottie from 'react-lottie-player';
import { useMediaQuery } from 'react-responsive'

//Animations
import LogoAnimation from '../../assets/animaciones/logo-small.json';
import LogoAnimationBig from '../../assets/animaciones/logo-big.json';

const Logo = () => {

	//State Animations
	const [toggleButton, setToggleButton] = useState(false);
	const [segmentFrom, setSegmentFrom] = useState(0);
  const [segmentTo, setSegmentTo] = useState(80);
  const [segmentsEnabled, setSegmentsEnabled] = useState(false);
  const [play, setPlay] = useState(true);
  const [loop, setLoop] = useState(false);
  const [loopTimes, setLoopTimes] = useState(0);
  const [speed, setSpeed] = useState(5);
  const [direction, setDirection] = useState(1);
  const segments = [segmentFrom, segmentTo];

	const [log, setLog] = useState([]);
  const addLog = (v) => setLog(l => [v, ...l]);

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	return (
		<Fragment>
			{isTabletOrMobile &&
				<Lottie
					loop={loop}
					speed={speed}
					play={play}
					animationData={LogoAnimation}
					direction={direction}
					segments={segmentsEnabled && segments}
					style={{ width: 200, height: 162, marginBottom: 0, alignSelf: 'center', cursor: 'pointer' }}
					onComplete={() => addLog('complete')}
					onLoopComplete={() => addLog('loopComplete')}
					onEnterFrame={() => { /* addLog('enterFrame') */ }}
					onSegmentStart={() => addLog('segmentStart')}
					onLoad={() => addLog('load')}
				/>
			}
			{isDesktopOrLaptop &&
				<Lottie
					loop={loop}
					speed={speed}
					play={play}
					animationData={LogoAnimationBig}
					direction={direction}
					segments={segmentsEnabled && segments}
					style={{ width: 250, height: 203, marginBottom: 0, alignSelf: 'center', cursor: 'pointer' }}
					onComplete={() => addLog('complete')}
					onLoopComplete={() => addLog('loopComplete')}
					onEnterFrame={() => { /* addLog('enterFrame') */ }}
					onSegmentStart={() => addLog('segmentStart')}
					onLoad={() => addLog('load')}
				/>
			}
			{isBigScreen &&
				<Lottie
					loop={loop}
					speed={speed}
					play={play}
					animationData={LogoAnimationBig}
					direction={direction}
					segments={segmentsEnabled && segments}
					style={{ width: 500, height: 405, marginBottom: 0, alignSelf: 'center', cursor: 'pointer' }}
					onComplete={() => addLog('complete')}
					onLoopComplete={() => addLog('loopComplete')}
					onEnterFrame={() => { /* addLog('enterFrame') */ }}
					onSegmentStart={() => addLog('segmentStart')}
					onLoad={() => addLog('load')}
				/>
			}
		</Fragment>
	 );
}

export default Logo;