import React, { Fragment, useState } from 'react';

//Libraries
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useMediaQuery } from 'react-responsive';

//Layout

//Styles

//Images
import image00 from '../../../assets/imagenes/galeria/barranquilla/barranquilla01.jpg';
import image01 from '../../../assets/imagenes/galeria/barranquilla/barranquilla02.jpg';
import image02 from '../../../assets/imagenes/galeria/barranquilla/barranquilla03.jpg';
import image03 from '../../../assets/imagenes/galeria/barranquilla/barranquilla04.jpg';
import image04 from '../../../assets/imagenes/galeria/barranquilla/barranquilla05.jpg';
import image05 from '../../../assets/imagenes/galeria/barranquilla/barranquilla06.jpg';
import image06 from '../../../assets/imagenes/galeria/barranquilla/barranquilla07.jpg';
import image07 from '../../../assets/imagenes/galeria/barranquilla/barranquilla08.jpg';
import image08 from '../../../assets/imagenes/galeria/barranquilla/barranquilla09.jpg';
import image09 from '../../../assets/imagenes/galeria/barranquilla/barranquilla10.jpg';
import image10 from '../../../assets/imagenes/galeria/barranquilla/barranquilla11.jpg';
import image11 from '../../../assets/imagenes/galeria/barranquilla/barranquilla12.jpg';
import image12 from '../../../assets/imagenes/galeria/barranquilla/barranquilla13.jpg';
import image13 from '../../../assets/imagenes/galeria/barranquilla/barranquilla14.jpg';
import image14 from '../../../assets/imagenes/galeria/barranquilla/barranquilla15.jpg';
import image15 from '../../../assets/imagenes/galeria/barranquilla/barranquilla16.jpg';
import image16 from '../../../assets/imagenes/galeria/barranquilla/barranquilla17.jpg';
import image17 from '../../../assets/imagenes/galeria/barranquilla/barranquilla18.jpg';
import image18 from '../../../assets/imagenes/galeria/barranquilla/barranquilla19.jpg';
import image19 from '../../../assets/imagenes/galeria/barranquilla/barranquilla20.jpg';
import image20 from '../../../assets/imagenes/galeria/barranquilla/barranquilla21.jpg';
import image21 from '../../../assets/imagenes/galeria/barranquilla/barranquilla22.jpg';
import image22 from '../../../assets/imagenes/galeria/barranquilla/barranquilla23.jpg';
import image23 from '../../../assets/imagenes/galeria/barranquilla/barranquilla24.jpg';
import image24 from '../../../assets/imagenes/galeria/barranquilla/barranquilla25.jpg';
import image25 from '../../../assets/imagenes/galeria/barranquilla/barranquilla26.jpg';
import image26 from '../../../assets/imagenes/galeria/barranquilla/barranquilla27.jpg';

const images = [
  image00,
	image01,
	image02,
	image03,
	image04,
	image05,
	image06,
	image07,
	image08,
	image09,
	image10,
	image11,
	image12,
	image13,
	image14,
	image15,
	image16,
	image17,
	image18,
	image19,
	image20,
	image21,
	image22,
	image23,
	image24,
	image25,
	image26,
];

const Barranquilla = () => {

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	const [getPhotoIndex, setPhotoIndex] = useState(0);
	const [getIsOpen, setIsOpen] = useState(false);

	//Libraries-Carousel
	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 1
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1
		}
	};

	return (
		<Fragment>
			<Carousel
				swipeable={isDesktopOrLaptop ? true : true}
				draggable={isDesktopOrLaptop ? true : true}
				showDots={isDesktopOrLaptop ? false : false}
				responsive={responsive}
				ssr={true} // means to render carousel on server-side.
				infinite={false}
				//autoPlay={this.props.deviceType !== "mobile" ? true : false}
				autoPlay={false}
				autoPlaySpeed={1}
				keyBoardControl={true}
				customTransition="all 2"
				transitionDuration={500}
				containerClass="pictures-container"
				removeArrowOnDeviceType={["tablet", "mobile"]}
				//deviceType={this.props.deviceType}
				dotListClass="custom-dot-list-style"
				itemClass="pictures--item"
				className="pictures"
				customButtonGroup={ isDesktopOrLaptop ? null : null }
				arrows={isDesktopOrLaptop ? false : false}
			>
				<div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(0)] }>
								<img src={image00} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(1)]}>
								<img src={image01} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(2)] }>
								<img src={image02} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(3)]}>
								<img src={image03} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(4)] }>
								<img src={image04} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(5)]}>
								<img src={image05} />
							</button>
						</div>
				</div>

				<div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(6)]}>
								<img src={image06} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(7)]}>
								<img src={image07} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(8)]}>
								<img src={image08} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(9)]}>
								<img src={image09} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(10)]}>
								<img src={image10} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(11)]}>
								<img src={image11} />
							</button>
						</div>
				</div>
				<div>
					<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(12)]}>
								<img src={image12} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(13)]}>
								<img src={image13} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(14)]}>
								<img src={image14} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(15)]}>
								<img src={image15} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(16)]}>
								<img src={image16} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(17)]}>
								<img src={image17} />
							</button>
						</div>
				</div>

				<div>
					<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(18)]}>
								<img src={image18} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(19)]}>
								<img src={image19} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(20)]}>
								<img src={image20} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(21)]}>
								<img src={image21} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(22)]}>
								<img src={image22} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(23)]}>
								<img src={image23} />
							</button>
						</div>
				</div>

				<div>
					<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(24)]}>
								<img src={image24} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(25)]}>
								<img src={image25} />
							</button>
						</div>
						<div className="pictures--item__img">
							<button type="button" onClick={() => [setIsOpen(true) , setPhotoIndex(26)]}>
								<img src={image26} />
							</button>
						</div>
				</div>
			</Carousel>

			{getIsOpen && (
          <Lightbox
            mainSrc={images[getPhotoIndex]}
            nextSrc={images[(getPhotoIndex + 1) % images.length]}
            prevSrc={images[(getPhotoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((getPhotoIndex+ images.length - 1) % images.length,)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((getPhotoIndex+ 1) % images.length,)
            }
						enableZoom={false}
          />
        )}
		</Fragment>
	 );
}

export default Barranquilla;