import React, { Fragment, useState, useEffect } from 'react';

//Libraries
import { useMediaQuery } from 'react-responsive';
import { gsap, TimelineLite, Power3 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

//Layout
import Header from './layout/header';
import Mouse from './layout/mouse';
import Logo from './layout/logo';
import Footer from './layout/footer';

//Sections
import SectionOne from './section/section01';
import SectionTwo from './section/section02';
import SectionThree from './section/section03';
import SectionFour from './section/section04';
import SectionFive from './section/section05';
import SectionSix from './section/section06';

const Index = () => {
	//State Gsap
	let t1 = new TimelineLite ( { delay: 0.3 } );
	gsap.registerPlugin(ScrollTrigger);


	//State Scroll
	const [getStart, setStart] = useState(false);
	const [getHeaderCurrentSection, setHeaderCurrentSection] = useState();
	const [getPassCurrentSection, setPassCurrentSection] = useState('#home');
	const [getPosInitial, setPosInitial] = useState();
	const [getStarsFade, setStarsFade] = useState(true);

	//UseEffect Smooth Scroll
	useEffect(() => {
		let buttons = document.querySelectorAll('.anchor');

		buttons.forEach((button) => {
    		button.addEventListener('click', (e) => {
      e.preventDefault();
      let target = e.currentTarget.getAttribute('href');

      	document.querySelector(target).scrollIntoView({
				 block: "start",
        behavior: 'smooth',
      	});
    	});
  	}, []);
	}, []);

	//Function Start By Button
	const startByButton  = () => {
		setTimeout(() => {
			const getPosInitial = document.querySelector('#one').offsetTop;
			const plusPos = getPosInitial + 10;
			window.scrollTo( 0, plusPos );
			console.log(plusPos);
		}, 300);
	}

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})


	return (
		<Fragment>

			<Header setStart={setStart} setPosInitial={setPosInitial} setPassCurrentSection={setPassCurrentSection}></Header>

			<section className={ getStart ? "index index__text--flexcenter" : "index index__text--flexcenter start" } id="home">
				<div className="index__logo">
					<Logo />
				</div>
				<article className="index__text" onMouseMove={() => setStart(true)}>
					<h1>Un formato exitoso</h1>
					{isTabletOrMobile &&
						<p>
							El concurso que busca al doble perfecto de tu artista favorito volvió cargado de sorpresas y millonarios premios. No te pierdas este recorrido por lo mejor de sus 7 temporadas.
					</p>
					}

					{isDesktopOrLaptop &&
						<p>
							El concurso que busca al doble perfecto<br />  de tu artista favorito volvió cargado de sorpresas y millonarios premios.<br />  No te pierdas este recorrido por lo mejor de sus 7 temporadas.
						</p>
					}

					{isBigScreen &&
						<p>
							El concurso que busca al doble perfecto de tu artista favorito volvió cargado de sorpresas y millonarios premios. No te pierdas este recorrido por lo mejor de sus 7 temporadas.
						</p>
					}
				</article>
				<article className="index__button">
					<a className="index__button--mouse anchor" onClick={() => [setStart(true), setHeaderCurrentSection('#one'), startByButton()]} onMouseOver={() => [setStart(true), setHeaderCurrentSection('#one')]} href="#one">
						<Mouse />
						<span>Desliza para<br /> ver más</span>
					</a>
				</article>
			</section>

			<section className="section--container__sections" id="one">
				<SectionOne />
			</section>

			<section className="section--container__sections section--container__background" id="two">
				<SectionTwo />
			</section>

			<section className="section--container__sections section--container__titlespace" id="three">
				<SectionThree />
			</section>

			<section className="section--container__sections section--container__conquering section--container__background" id="four">
				<SectionFour />
			</section>

			<section className="section--container__sections section--container__cast" id="five">
				<SectionFive getPassCurrentSection={getPassCurrentSection} />
			</section>

			<section className="section--container__sections
				section--container__curius" id="six">
				<SectionSix />
			</section>

			{ getStart ?
				<>
					{/* // Footer */}
					<Footer />
				</>
				:
				null
			}
		</Fragment>
	 );
}

export default Index;