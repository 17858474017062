import React, { Fragment } from 'react';

//Libraries
import StarfieldAnimation from 'react-starfield-animation';

const Stars = () => {
	return (
		<Fragment>

			{/* // Start Particles Component */}

			<StarfieldAnimation
				style={{
					position: 'fixed',
					width: '100%',
					height: '100%',
					top: 0,
					left: 0
				}}
				numParticles={20}
    />
		</Fragment>
	);
}

export default Stars;