import React, { Fragment, useEffect } from 'react';

//Libraries
import { useMediaQuery } from 'react-responsive';
import Aos from 'aos';
import 'aos/dist/aos.css';

//Layout
import CarouselSectionOne from '../elements/carousel';
import Tvnovelas from '../layout/tvnovelas';
import India from '../layout/india';

//Styles
import { AwardsStyles } from '../../styles/gstyles';

//Images
import {ReactComponent as Star} from '../../assets/imagenes/star.svg';

const SectionOne = () => {

	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);

	//Const Mediaqueries
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

	return (
		<Fragment>
			{isTabletOrMobile &&
				<h2 id="one-anchor" data-aos="fade-down">Rating<br /> 7 temporadas</h2>
			}

			<CarouselSectionOne />

			{/* // Awards Styles */}
			<AwardsStyles />

			<div className="awards">
				{isDesktopOrLaptop &&
					<h2 id="one-anchor" data-aos="fade-left">Rating de<br /> 7 temporadas</h2>
				}

				<div className="awards--type awards--type__tv" data-aos={isTabletOrMobile ? "fade-down" : "fade-up"}>
					<div className="awards--type__award">
						<Tvnovelas />
					</div>
					<h3>Premios<br />TV y Novelas</h3>
					<ul>
						<li>
							<p><Star /><b>Año:</b> 2012</p>
						</li>
						<li>
							<p><Star /><b>Ganador en la categoría:</b></p>
							<p className="awards--type__text">Programa concurso o reality favorito.</p>
						</li>
					</ul>
				</div>
				<div className="awards--type awards--type__india" data-aos="fade-down">
					<div className="awards--type__award">
						<India />
					</div>
					<h3>Premios<br/>India Catalina</h3>
					<ul>
						<li>
						<p><Star /><b>Año:</b> 2012</p>
						</li>
						<li>
							<p><Star /><b>Ganador en 3 categorías:</b></p>
							<p className="awards--type__text">Innovación o Mejor nuevo formato de televisión.</p>
							<p className="awards--type__text">Mejor programa de entretenimiento.</p>
							<p className="awards--type__text">Mejor programa de concurso.</p>
						</li>
					</ul>
				</div>
			</div>

		</Fragment>
	 );
}

export default SectionOne;