import React, { Fragment, useEffect } from 'react';

//Libraries
import { useMediaQuery } from 'react-responsive';
import Aos from 'aos';
import 'aos/dist/aos.css';

//Layout
import GalleryMobile from '../elements/gallerymobile';
import GalleryDesktop from '../elements/gallerydesktop';

//Styles

//Images

const SectionFour = () => {

	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);


	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	return (
		<Fragment>
			{isTabletOrMobile &&
				<h2 id="four-anchor" data-aos="fade-down">Conquistando<br />territorios<br />en busca de<br />talento</h2>
			}

			{isDesktopOrLaptop &&
				<h2 id="four-anchor" data-aos="fade-down">Conquistando territorios<br />en busca de talento</h2>
			}

			{isBigScreen &&
				<h2 id="four-anchor" data-aos="fade-down">Conquistando territorios<br />en busca de talento</h2>
			}

			<p id="four-text" className="four-text" data-aos="fade-down">Yo me llamo ha buscado a los dobles perfectos en más de 10 ciudades de Colombia,
entre ellas Bogotá, Pereira, Cali, Bucaramanga, Medellín, Barranquilla, Pasto, Villavicencio, Ibagué, Tunja y en el Eje Cafetero. Revive momentos históricos de sus audiciones.</p>

			{isTabletOrMobile &&
				<GalleryMobile />
			}

			{isDesktopOrLaptop &&
				<GalleryDesktop />
			}

			{isBigScreen &&
				<GalleryDesktop />
			}

		</Fragment>
	 );
}

export default SectionFour;