import React, {Fragment} from 'react';

//Libraries
import { useMediaQuery, useState } from 'react-responsive';
import Aos from 'aos';
import 'aos/dist/aos.css';

//Layout

//Styles

//Images

const SectionSix = () => {

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	return (
		<Fragment>

			{isTabletOrMobile &&
				<h2 id="six-anchor" data-aos="fade-down">Datos<br />curiosos</h2>
			}

			{isDesktopOrLaptop &&
				<h2 id="six-anchor" data-aos="fade-left">Datos curiosos</h2>
			}

			{isBigScreen &&
				<h2 id="six-anchor" data-aos="fade-left">Datos curiosos</h2>
			}

			<div className="facts">
				<div className="facts--item facts--item__one" data-aos="fade-down">
					<p>Por primera vez en 7 temporadas, Yo me Llamo entregará 1200 millones de pesos.</p>
				</div>

				<div className="facts--item facts--item__two" data-aos="fade-down">
					<p>A diferencia de otras temporadas, en 2021 las mujeres imitadoras se animaron a presentarse en más cantidad.</p>
				</div>

				<div className="facts--item facts--item__three" data-aos="fade-down">
					<p>Los jóvenes se han atrevido más a imitar a diferentes leyendas que caracterizan a la música colombiana.</p>
				</div>
			</div>
		</Fragment>
	 );
}

export default SectionSix;