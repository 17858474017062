import './App.css';

// Styles
import { Gstyles, HomeStyles, Animation } from './styles/gstyles';

// Layout
import Stars from '../src/components/layout/stars';

// Components
import Home from './components/home';

function App() {
  return (
    <>

      {/* // General Styles */}
      <Gstyles />

      {/* // Home Styles */}
			<HomeStyles />

      {/* // Home  */}
      <Home />

      {/* // Animation */}
      <Animation />

      <Stars />

    </>
  );
}

export default App;
