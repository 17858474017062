import { createGlobalStyle } from 'styled-components';
// Devices like mediaqueryes
import { device } from './rstyles';

// Images
import gbackground from '../assets/imagenes/bg.jpg';
import mbackground from '../assets/imagenes/bg-menu.jpg';
import gfbackground from '../assets/imagenes/bg-fake.jpg';
import star from '../assets/imagenes/star.png';
import starbig from '../assets/imagenes/star-big.png';
import camera from '../assets/imagenes/camera.png';
import camerabig from '../assets/imagenes/camera-big.png';
import arrowbutton from '../assets/imagenes/arrow-button.png';

import gbackgroundMobile from '../assets/imagenes/bg_MV.jpg';
import gfbackgroundMobile from '../assets/imagenes/bg_MV_fake.jpg';
import gbackgroundLaptop from '../assets/imagenes/bg_DK.jpg';
import gfbackgroundLaptop from '../assets/imagenes/bg_DK_fake.jpg';
import gbackgroundSmallLaptop from '../assets/imagenes/bg_SDK.jpg';

import ornamentsMobileTop from '../assets/imagenes/ornaments__mobile--top.png';
import ornamentsMobileBottom from '../assets/imagenes/ornaments__mobile--bottom.png';
import ornamentsDesktopTop from '../assets/imagenes/ornaments__desktop--top.png';
import ornamentsDesktopBottom from '../assets/imagenes/ornaments__desktop--bottom.png';


// Variables
import { colors, mixins, fonts, gap, reset, flex } from './vstyles';

// Nomenclatura BEM  block + __ + element +--+ modifier all on lowercase

export const Gstyles = createGlobalStyle`
	html {
		${mixins.box}
		scroll-behavior: smooth;
		font-size: 62.5%; /* 1REM = 10px */

		@media only screen and ${device.laptop} {
			font-size: 42.5%; /* 1REM = 10px */
		}

		@media only screen and ${device.laptopL} {
			font-size: 62.5%; /* 1REM = 10px */
		}
	}

	body {
		${mixins.box}
		font-size: 1.6rem;
		${fonts.font_01}
		color: ${colors.creamLight};
		background-color: ${colors.black};
		background-image: url(${ornamentsMobileTop}), url(${ornamentsMobileBottom}), url(${gfbackgroundMobile}), url(${gbackgroundMobile});
		background-size: 100% auto, 100% auto, 100% auto , 100% auto;
		background-repeat: no-repeat,  no-repeat, no-repeat, repeat;
		background-position-x: center, center, center, center;
		background-position-y: top, 96.7%, top, top;
		margin: 0;
		padding: 0;

		@media only screen and ${device.laptop} {
			background-image: url(${ornamentsDesktopTop}), url(${ornamentsDesktopBottom}),  url(${gbackgroundSmallLaptop});
			background-position-x: center, center, center, center;
			background-position-y: top, 98.5%, top, top;
			background-size: 100% auto, 100% auto, 100% 100%;
			background-color: ${colors.brown};
			margin: 0;
			padding: 0;
		}

		@media only screen and ${device.laptopL} {
			background-image: url(${ornamentsDesktopTop}), url(${ornamentsDesktopBottom}), url(${gfbackgroundLaptop}), url(${gbackgroundLaptop});
			background-position-x: center, center, center, center;
			background-position-y: top, 98.5%, top, top;
			background-size: 100% auto, 100% auto, 100vw auto, 100% 100%;
			background-color: ${colors.brown};
			margin: 0;
			padding: 0;
		}
	}

	* {
		${mixins.box}
		background-color: transparent;
		font-smooth: never;
		-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	}

	img {
		width: 100%;
	}

	p {
		${reset.space}
		${fonts.font_normal}

		.section--container__conquering &{
			padding: 0 ${gap.gap20} 0 ${gap.gap40};
			margin: 0 auto ${gap.gap40};
			${fonts.font_normal}
			font-size: 2.0rem;
			line-height: 1.3;
			position: relative;
			z-index: 2;

			@media only screen and ${device.laptop} {
				font-size: 3.2rem;
				max-width: calc(1478px / 2);
				text-align: center;
				padding: 0;
				margin: 0 auto 46px;
			}

			@media only screen and ${device.laptopL} {
				font-size: 3.2rem;
				max-width: 1478px;
				text-align: center;
				padding: 0;
				margin: 0 auto 92px;
			}
		}
	}

	h1 {
		${reset.space}
		font-size: 2.4rem;
		${fonts.font_00}
		font-stretch: normal;
		font-style: normal;
		line-height: 1.42;
		letter-spacing: normal;
		text-align: center;
		padding: 0 ${gap.gap5};

		@media only screen and ${device.laptop} {
			font-size: 6.4rem;
			line-height: normal;
		}
	}

	h2 {
		font-size: 4.0rem;
		font-weight: bold;
		${fonts.font_normal}
		line-height: 1;

		@media only screen and ${device.laptop} {
			font-size: 8.0rem;
		}

		.section--container__sections &{
			padding: 0 ${gap.gap20} 0 ${gap.gap40};
			margin: 0 auto ${gap.gap50};
			text-aling: left;
			color: ${colors.creamDark};

			@media only screen and ${device.laptop} {
				width: calc(100% - 101px);
				padding: 0 ${gap.gap10} 0 80px;
				margin: 0;
			}

			@media only screen and ${device.laptopL} {
				width: calc(100% - 402px);
				padding: 0 ${gap.gap20} 0 160px;
				margin: 0;
			}
		}

		.awards &{
			@media only screen and ${device.laptop} {
				padding: 0;
				width: auto;
			}

			@media only screen and ${device.laptopL} {
				padding: 0;
				width: auto;
			}
		}

		.section--container__titlespace &{
			padding: 0 ${gap.gap20} 0 ${gap.gap40};
			margin: 0 auto ${gap.gap50};
			text-aling: left;
			color: ${colors.creamDark};

			@media only screen and ${device.laptop} {
				width: 100%;
				padding: 0 ${gap.gap10} 0 ${gap.gap20};
				margin: 0 auto 107px;
				display: block;
				text-align: center;
			}

			@media only screen and ${device.laptopL} {
				width: 100%;
				margin: 0 auto 214px;
				display: block;
				text-align: center;
			}
		}

		.section--container__background &{
			@media only screen and ${device.laptop} {
				margin: 0 0 28px;
			}

			@media only screen and ${device.laptopL} {
				margin: 0 0 56px;
			}
		}

		.section--container__conquering &{
			padding: 0 ${gap.gap20} 0 ${gap.gap40};
			margin: 0 auto ${gap.gap20};

			@media only screen and ${device.laptop} {
				width: 100%;
				padding: 0;
				margin: 0 auto 14px;
				display: block;
				text-align: center;
			}

			@media only screen and ${device.laptopL} {
				width: 100%;
				padding: 0;
				margin: 0 auto 25px;
				display: block;
				text-align: center;
			}
		}

		.section--container__curius &{
			@media only screen and ${device.laptop} {
				margin: 0 auto 115px 0;
			}

			@media only screen and ${device.laptopL} {
				margin: 0 auto 57px 0;
			}
		}

	}

	section{
		${reset.space}
		padding: 0 ${gap.gap25};
		position: relative;
		z-index: 1;
		height: auto;
		min-height: 100vh;

		/* &.start{
			min-height: 100vh;
			margin: 0 0 30vw;
			.index__logo{
				margin: 55px auto 50px;

				@media only screen and ${device.laptop} {
					margin: 100px auto 18px;
				}

				@media only screen and ${device.laptopL} {
					margin: 200px auto 36px;
				}
			}
		} */

		&#one{
			@media only screen and ${device.laptop} {
				padding: 0 ;
			}

			@media only screen and ${device.laptopL} {
				padding: 0 ;
			}
		}

		&.section--container__sections{
			padding: ${gap.gap120} 0 0 ;
		}
		&.section--container__background{
			padding:${gap.gap120} 0 0 ;

			@media only screen and ${device.laptop} {
				padding:222px 0 0 ;
			}

			@media only screen and ${device.laptopL} {
				padding:444px 0 0 ;
			}

		}
		&.section--container__titlespace{
			padding:${gap.gap120} 0 0 ;

			@media only screen and ${device.laptop} {
				padding:222px 0 0 ;
			}

			@media only screen and ${device.laptopL} {
				padding:444px 0 0 ;
			}
		}
		&.section--container__conquering {
			padding:${gap.gap120} 0 0 ;

			@media only screen and ${device.laptop} {
				padding:222px 0 0 ;
			}

			@media only screen and ${device.laptopL} {
				padding:444px 0 0 ;
			}

			&.section--container__background{
				&:before {
					display: none;

					@media only screen and ${device.laptop} {
						display: block;
					}
				}
			}
		}
		&.section--container__cast{
			padding:${gap.gap120} 0 0;

			@media only screen and ${device.laptop} {
				padding:300px 0 0 ;
			}

			@media only screen and ${device.laptopL} {
				padding:599px 0 0 ;
			}
		}
		&.section--container__curius{
			padding:${gap.gap70} 0 0 ;

			@media only screen and ${device.laptop} {
				padding:100px 0 133px;
			}

			@media only screen and ${device.laptopL} {
				padding:199px 0 266px;
			}
		}
	}

	.ril__inner img {
		width: 100%;

		@media only screen and ${device.laptop} {
			width: auto;
		}

		@media only screen and ${device.laptopL} {
			width: auto;
		}
	}
`;


export const HomeStyles = createGlobalStyle`
	.index{
		${flex.centerdc}
		${mixins.box}
		height: auto;
		min-height: 100vh;
		background: transparent;
		position: relative;
		padding: 0;
		margin: 0;
		&__logo{
			${flex.center}
			justify-content: center;
			margin: 55px auto ${gap.gap50};
			width: 200px;
			height: auto;
			position: relative;
			z-index: 2;
			img {
				min-width: 100%;
				height: auto;
			}

			@media only screen and ${device.laptop} {
				margin: 100px auto 18px;
				width: 250px;
				height: auto;
			}

			@media only screen and ${device.laptopL} {
				margin: 200px auto 18px;
				width: 500px;
				height: auto;
			}

		}
		&__text{
			${flex.center}
			margin: 0 auto ${gap.gap20};
			width: 100%;
			max-width: 300px;
			font-size: 1.8rem;
			line-height: 1.44;
			text-align: center;
			position: relative;
			z-index: 2;
			.index__text--flexcenter &{
				${flex.centerdc}
				${mixins.box}
			}

			@media only screen and ${device.laptop} {
				font-size: 3.2rem;
				line-height: 1.31;
				max-width: calc(1456px - (1456px / ${mixins.indicator}));
				margin: 0 auto ${gap.gap25};
			}

			@media only screen and ${device.laptopL} {
				font-size: 3.2rem;
				line-height: 1.31;
				max-width: 1456px;
				margin: 0 auto ${gap.gap50};
			}
		}
		&__button{
			${flex.center}
			justify-content: center;
			margin: 0 auto;
			width: 100%;
			max-width: 300px;
			position: relative;
			z-index: 2;
			text-transform: uppercase;
			&--mouse{
				${flex.centerdc}
				${mixins.box}
				justify-content: center;
				font-size: 1.28rem;
				${fonts.font_normal}
				letter-spacing: 4.27px;
				transition: all .2s ease-in;
				text-decoration: none;
				span{
					text-align: center;
					color: #f1d19e;
					font-family: 'Rubik', sans-serif;
					text-decoration: none;
				}
			}

			@media only screen and ${device.laptop} {
				&--mouse{
					span{
						font-size: 2.0rem;
					}
					&:hover{
						transform: scale(1.1);
					}
				}
			}
		}
	}
`;

export const HeaderStyles = createGlobalStyle`
	.header, header{
		${flex.centerdr}
		${mixins.box}
		align-items: center;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 7;
		width: 100%;
		height: 60px;
		background-color: rgba(255, 255, 255, 0.05);
		margin: 0 0 59px;
  	padding: 0 0 0 ${gap.gap20};
		opacity: 0;
		transition: all 0.6s linear;
		transform: translateY(-70px);
		-webkit-backdrop-filter: blur(3.5px);
		backdrop-filter: blur(3.5px);

		@media only screen and ${device.laptop} {
			transform: translateY(-55px);
			justify-content: space-between;
			z-index: 5;
		}

		@media only screen and ${device.laptopL} {
			transform: translateY(-110px);
			justify-content: space-between;
			z-index: 5;
		}

		&:after{
			content: '';
			width: 100%;
			height: 1px;
			background-color: ${colors.creamLight};
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			opacity: 0.2;
		}

		@media only screen and ${device.laptop} {
			height: calc(100px - (100px / ${mixins.indicator}));
			width: calc(100% - 64px);
			left: inherit;
			right: 0;
			padding: 0 calc(62px - (62px / ${mixins.indicator})) 0 calc(28px - (28px / ${mixins.indicator}));
		}

		@media only screen and ${device.laptopL} {
			height: 100px;
			width: calc(100% - 96px);
			left: inherit;
			right: 0;
			padding: 0 125px 0 54px;
		}

		&.appear {
			transform: translateY(0);
			opacity: 1;
		}

		&.disappear {
			opacity: 0;
			transform: translateY(-70px);

			@media only screen and ${device.laptop} {
				transform: translateY(-55px);
			}

			@media only screen and ${device.laptopL} {
				transform: translateY(-110px);
			}
		}

		&__logo{
			${flex.centerdc}
			align-items: center;
			justify-content: center;
			width: 64px;
			height: 60px;
			svg {
				min-width: 100%;
				height: 46px;
			}

			@media only screen and ${device.laptop} {
				width: calc(106px - (106px / ${mixins.indicator}));
				height: calc(76px - (76px / ${mixins.indicator}));

				svg {
					min-width: 100%;
					height: calc(76px - (76px / ${mixins.indicator}));
				}
			}

			@media only screen and ${device.laptopL} {
				width: 106px;
				height: 76px;

				svg {
					min-width: 100%;
					height: 76px;
				}
			}
		}
		&__menubutton{
			${flex.center}
			width: 60px;
			height: 60px;
			&_buttonsize {
				div{
					width: 100%;
				}
			}
		}

		.header__asidebar {
			position: fixed;
			transition: all 0.2s linear;
			background-color: rgba(0,0,0,.5);
			width: calc(97px - (97px / ${mixins.indicator}));
			height: 100vh;
			left: -64px;
			top: 0;
			opacity: 0;
			${flex.center}
			justify-content: center;

			@media only screen and ${device.laptopL} {
				width: 97px;
				height: 100vh;
				left: -97px;
			}

			&.last{
				height: 50vh;
			}

			&--menu{

				transition: all linear .2s;
				opacity: 0;

				ul{
					list-style: none;
					padding: 0;
					margin: 0 auto;
					${flex.centerdc}
					justify-content: center;
					align-items: center;
					a{
						text-decoration: none;
						${fonts.font_00}
						${fonts.font_normal}
						font-weight: bold;
						font-size: 2.4rem;
						color: ${colors.creamDark};
						opacity: 0.4;
						transition: all linear .2s;
					}
					li{
						${flex.centerdc}
						justify-content: center;
						align-items: center;
						&:after{
							content: '';
							transition: all linear .2s;
							width: 1px;
							height: 0;
							margin: calc(9px - (9px / ${mixins.indicator})) calc(16px - (16px / ${mixins.indicator})) calc(8px - (8px / ${mixins.indicator}));
							background-color: ${colors.creamDark};

							@media only screen and ${device.laptopL} {
								margin: 9px 16px 8px;
							}

						}
						&:last-of-type{
							&:after{
								display: none;
							}
						}
					}
					li.active{
						a{
							color: ${colors.yellow};
							opacity: 1;
						}
						&:after{
							height: calc(67px - (67px / ${mixins.indicator}));

							@media only screen and ${device.laptopL} {
								height: 67px;
							}
						}
					}
				}

				&.appear{
					opacity: 1;
				}

				&.disappear{
					opacity: 0;
				}

			}
		}

		&.appear {
			.header__asidebar {
				opacity: 1;
			}
		}

		.btn-video {
			width: calc(170px - (170px / ${mixins.indicator}));
			height: calc(48px - (48px / ${mixins.indicator}));
			margin: calc(14px - (14px / ${mixins.indicator})) 0 calc(25px - (25px / ${mixins.indicator})) 0;
			line-height: calc(48px - (48px / ${mixins.indicator}));
			padding: 0 calc(50px - (50px / ${mixins.indicator})) 0 calc(50px - (50px / ${mixins.indicator}));
			border-radius: calc(8px - (8px / ${mixins.indicator}));
			border: solid thin ${colors.creamLight};
			background-image: linear-gradient(to bottom, #e8c56b, #b5861c);
			font-family: 'Rubik', sans-serif;
			font-size: 2.0rem;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			color: ${colors.black};
			text-decoration: none;

			@media only screen and ${device.laptopL} {
				width: 170px;
				height: 48px;
				margin: 14px 0 25px 0;
				padding: 0 60px 0 55px;
				border-radius: 8px;
				line-height: 48px;
			}
		}
	}

	.right-buttons{
		position: fixed;
		top: 50%;
		transform: translateY(-50%);
		transition: all linear .6s;
		right: -51.3px;
		width: 38px;
		height: calc(154px - (154px - ${mixins.indicator}));
		background-color: rgba(0,0,0,0);
		${flex.centerdc}
		${mixins.box}
		z-index: 3;

		@media only screen and ${device.laptopL} {
			right: -77px;
			width: 77px;
			height: 154px;
		}

		&.appear{
			right: 60px;

			@media only screen and ${device.laptopL} {
				right: 77px;
			}
		}

		&.disappear{
			right: -60px;

			@media only screen and ${device.laptopL} {
				right: -77px;
			}
		}

		p {
			color: white;
		}

		a{
			width: 38.5px;
			height: 38.5px;
			${flex.centerdc}
			${mixins.box}
			cursor: pointer;
			margin: 2.5px 0 ;
			transition: all linear .2s;
			opacity: 1;
			position: relative;

			@media only screen and ${device.laptopL} {
				width: 77px;
				height: 77px;
				margin: 5px 0 ;
			}

			&.disappear {
				opacity: 0;
			}

			&:after{
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background: transparent;
				top: 0;
				left: 0;
			}

			img{
				width: 100%;
				max-width: 38.5px;

				@media only screen and ${device.laptopL} {
					max-width: 77px;
				}
			}

			&#next{
				transform: rotate(-180deg);
			}

			&:hover{
				opacity: .4;
				transform: scale(1.1);

				&#next {
					opacity: .4;
					transform: scale(1.1) rotate(-180deg);
				}
			}
		}
	}

	.ligthbox-video, #ligthbox-video{
		position: fixed;
		z-index: 5;

		>div{
			background-color: rgba(0,0,0,0.8) !important;
		}

		iframe{
			@media only screen and ${device.laptop} {
				width: 640px;
				height: 360px;
			}

			@media only screen and ${device.laptopL} {
				width: 1280px;
				height: 720px;
			}
		}
	}
`;

//* ONLY MOBILE *//

export const MenuStyles = createGlobalStyle`
	.menu{
		${flex.center}
		${mixins.box}
		align-items: flex-start;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 6;
		width: 100%;
		height: 100vh;
		margin: 0;
  	padding: 18vh 0 0 0;
		&__list {
			padding: 0;
			margin: 0 auto;
			list-style: none;
			position: relative;
			z-index: 3;
			li {
				${flex.topdr}
				align-items: flex-start;
				margin: 0 auto ${gap.gap30};
				animation-name: appear-back;
				animation-duration: 6s;
				animation-fill-mode: forwards;
				opacity: 0;

				&.active{
					a, span{
						color: ${colors.yellow};
						opacity: 1;
					}
					span{
						&:after{
							height: 75px;
							margin: 55px 0 0 0;
						}
					}
				}

				&:last-of-type {
					span{
						&:after{
							display: none;
						}
					}
				}
			}
			a {
				${flex.centerdc}
				${fonts.font_00}
				color: ${colors.creamDark};
				font-size: 2.4rem;
				margin: 0 0 0 ${gap.gap20};
				cursor: pointer;
				${fonts.font_normal}
				line-height: 2.4rem;
				opacity: .5;
				text-decoration: none;
			}
			span {
				${flex.centerdc}
				${fonts.font_00}
				align-items: center;
				color: ${colors.creamDark};
				font-size: 3.2rem;
				margin: 0;
				font-stretch: normal;
				font-style: normal;
				line-height: 50%;
				letter-spacing: normal;
				opacity: .5;
				text-align: center;

				&:after{
					content: '';
					transition: all linear .2s;
					width: 1px;
					height: 0;
					margin: 0;
					background-color: ${colors.creamDark};
					display: block;
				}
			}
		}

		&__back{
			position: absolute;
			left:0;
			top: 0;
			z-index: 1;
			-webkit-backdrop-filter: blur(10.4px);
			backdrop-filter: blur(10.4px);
			width: 100%;
			height: 100vh;

			&:before{
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba(255, 255, 255, 0.05);
				background-image: url(${mbackground});
				background-size: 100% auto;
				background-repeat: no-repeat;
				background-position-x: center;
				background-position-y: top;
				-webkit-backdrop-filter: blur(10.4px);
				backdrop-filter: blur(10.4px);
				animation-name: appear-back;
				animation-duration: 2s;
				animation-fill-mode: forwards;
				opacity: 0;
			}

			@keyframes appear-back {
				from {
					opacity: 0;
				}
				to {
					opacity: .97;
				}
			}
		}
	}
`;

//* END ONLY MOBILE *//

export const FooterStyles = createGlobalStyle`
	.footer{
		${flex.centerdc}
		${mixins.box}
		z-index: 2;
		width: 100%;
		height: auto;
		min-height: 220px;
		background-color: #212121;
		margin: 0 auto;
  	padding: 0;
		position: relative;

		@media only screen and ${device.laptop} {
			min-height: 110px;
		}

		@media only screen and ${device.laptopL} {
			min-height: 220px;
		}

		svg {
			display: block;
			margin: 0 auto;
			width: 100px;
		}
		&__list{
			list-style: none;
			width: 100%;
			${flex.center}
			justify-content: center;
			margin: ${gap.gap20} auto 0;
			padding: 0;
			li {
				text-align: center;
			}
			a {
				font-size: 1.6rem;
				${fonts.font_normal}
				letter-spacing: normal;
				text-decoration: none;
				color: ${colors.creamLight};
			}
			span {
				margin: 0 ${gap.gap5};
			}
		}

		@media only screen and ${device.laptop} {
			${flex.centerdr}
			justify-content: space-between;
			align-items: center;
			margin: 0;
			padding: 0 47px 0 77px;
			svg {
				margin: 0;
				width: 57.5px;
				height: 41px;
			}
			&__list{
				width: auto;
				justify-content: flex-end;
				margin: 0;

				a{
					font-size: 2.4rem;
					transition: all 0.2s ease-out;
					&:hover{
						color: ${colors.creamDark};
					}
				}
			}
		}

		@media only screen and ${device.laptopL} {
			${flex.centerdr}
			justify-content: space-between;
			align-items: center;
			margin: 0;
			padding: 0 94px 0 154px;
			svg {
				margin: 0;
				width: 115px;
				height: 82px;
			}
			&__list{
				width: auto;
				justify-content: flex-end;
				margin: 0;

				a{
					font-size: 2.4rem;
				}
			}
		}

	}
`;

export const CarouselStyles = createGlobalStyle`
	.carousel {
		overflow-y: visible;
		position: relative;
		padding: ${gap.gap10} 0 ${gap.gap10};
		margin: 0 0 calc(${gap.gap40} * 2);
		min-height: 430px;
		align-items: flex-start;

		@media only screen and ${device.laptop} {
			cursor: pointer;
			height: calc(880px - (880px / ${mixins.indicator}));
			align-items: center;
			padding: 0 0 ${gap.gap10};
		}

		@media only screen and ${device.laptopL} {
			cursor: pointer;
			height: 880px;
			align-items: center;
			padding: 0 0 ${gap.gap10};
		}

		.react-multi-carousel-track{
			position: relative;
			z-index: 2;
		}

		&--item{
			width: 140px;
			min-height: 315px;
			height: auto;
			${flex.center}
			${mixins.box}
			justify-content: center;
			transition: none;
			overflow: hidden;

			@media only screen and ${device.laptop} {
				width: calc(277px - (277px / ${mixins.indicator}));
				min-height: calc(650px - (650px / ${mixins.indicator}));
				;
			}

			@media only screen and ${device.laptopL} {
				width: 277px;
				min-height: 650px;
			}

			>div{
				${mixins.box}
				${flex.centerdc}
				align-items: center;
				transition: none;
				transform-origin: 50% 50%;
				width: 140px;
				height: 240px;
				right: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				@media only screen and ${device.laptop} {
					width: calc(416px - (416px / ${mixins.indicator}));
					height: calc(300px - (300px / ${mixins.indicator}));;
				}

				@media only screen and ${device.laptopL} {
					width: 416px;
					height: 600px;
				}

				.carousel--item__img{
					position: relative;
					border-radius: 50%;
					overflow: hidden;
					width: 98%;
					height: 98%;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);

					.shimmer {
						position: absolute;
						max-width: 100%
						max-width: 100%
						padding: 50%;
						margin: 50%;
						transform: translate(-50%, -50%);
						border-radius: 50%;
					}

					img{
						width: 100%;
						height: 100%;
					}
				}

				.carousel--item__circle{
					border-radius: 50%;
					width: 140px;
					height: 140px;
					display: block;
					box-shadow: 0 1px 32px 0 rgba(0, 0, 0, 0.6);
					background-image: linear-gradient(to bottom, #b5861c, #e8c56b);
					transition: opacity .2s ease-in;
					opacity: 0.5;

					animation-name: turn-gradient;
					animation-duration: 6s;
					animation-fill-mode: forwards;
					opacity: 0;

					@keyframes turn-gradient {
						from {
							background-image: linear-gradient(to bottom, #b5861c, #e8c56b);
						}
						to {
							background-image: linear-gradient(to bottom, #e8c56b, #b5861c);
						}
					}

					@media only screen and ${device.laptop} {
						width: calc(426px - (426px / ${mixins.indicator}));
						height: calc(426px - (426px / ${mixins.indicator}));
					}

					@media only screen and ${device.laptopL} {
						width: 416px;
						height: 416px;
					}

					&:hover {
						animation-name: turn-gradient;
					}
				}
				.carousel--item__rating{
					${mixins.box}
					${flex.centerdc}
					align-items: center;
					position: absolute;
					top: 21px;
					right: -21px;
					border-radius: 50%;
					width: 69px;
					height: 69px;
					box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
					border: solid 2px ${colors.white};
					background-color: ${colors.creamLight};

					@media only screen and ${device.laptop} {
						width: calc(138px - (138px / ${mixins.indicator}));
						height: calc(138px - (138px / ${mixins.indicator}));
						top: 21px;
						right: -21px;
					}

					@media only screen and ${device.laptopL} {
						width: 138px;
						height: 138px;
						top: 21px;
						right: -21px;
					}

					p{
						color: ${colors.dgray};
						font-size: 2.4rem;
						${fonts.font_normal}
						text-align: center;
						@media only screen and ${device.laptop} {
							font-size: 4.8rem;
						}
					}

					span{
						${fonts.font_00}
						color: ${colors.dgray};
						font-size: 1.2rem;
						${fonts.font_normal}
						text-align: center;
						@media only screen and ${device.laptop} {
							font-size: 2.4rem;
						}
					}
				}
				h3{
					${fonts.font_00}
					text-align: center;
					color: ${colors.creamLight};
					margin: ${gap.gap15} 0 ${gap.gap5};
					font-size: 2.8rem;
					${fonts.font_normal}

					@media only screen and ${device.laptop} {
						margin: 0 0 calc(${gap.gap5} - (${gap.gap5} / ${mixins.indicator}));
					}

					@media only screen and ${device.laptopL} {
						margin: 0 0 ${gap.gap5};
					}
				}
				span{
					text-align: center;
					color: ${colors.creamLight};
					font-size: 2.0rem;
					${fonts.font_normal}
				}

				.carousel--item__text {
					${mixins.box}
					${flex.centerdc}
					align-items: center;
					justify-content: center;
					white-space: pre-wrap;
					white-space: -moz-pre-wrap;
					white-space: -o-pre-wrap;
				}
			}

			&.react-multi-carousel-item--active{
				margin: 0 auto;
				position: relative;
				overflow: visible;
				z-index: 3;

				@media only screen and ${device.laptop} {
					min-height: calc(516px - (516px / ${mixins.indicator}));
				}

				@media only screen and ${device.laptopL} {
					min-height: 516px;
				}

				.carousel--item__text {
					position: absolute;
					bottom: -140px;
					left: 50%;
					width: 90%;
					transform: translate(-50%, 0);
					height: 240px;

					@media only screen and ${device.laptop} {
						bottom: calc(-190px - (-190px / ${mixins.indicator}));
						height: calc(240px - (240px / ${mixins.indicator}));
						width: 180%;
					}

					@media only screen and ${device.laptopL} {
						bottom: -180px;
						height: 240px;
						width: 180%;
					}
				}

				h3{
					@media only screen and ${device.laptop} {
						font-size: 5.6rem;
					}
				}

				span{
					@media only screen and ${device.laptop} {
						font-size: 4.0rem;
					}
				}

				>div{
					position: absolute;
					min-width: 230px;
					min-height: 230px;
					height: auto;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);

					.carousel--item__circle{
						min-width: 230px;
						min-height: 230px;
						display: block;
						opacity: 1;
						position: relative;
						top: -50px;

						@media only screen and ${device.laptop} {
							margin: 0 0 calc(31px - (31px / ${mixins.indicator}));
							top: -10px;
						}

						@media only screen and ${device.laptopL} {
							margin: 0 0 31px;
							top: -20px;
						}
					}

					&:before{
						top: 1px;
						left: 1px;
						width: 229px;
						height: 229px;

						@media only screen and ${device.laptop} {
							display: none;
						}
					}
				}
			}

			&.sibling_zero{
				overflow: visible;
				z-index: 2;
				min-height: 315px;

				>div {
					right: 50%;
					top: 50%;
					transform: translate(120%, -15px);
					z-index: 1;
					transition: all 1 linear;
					animation-name: grown-left;
					animation-duration: 1s;
					animation-fill-mode: forwards;
					opacity: 0;

					@keyframes grown-left {
						from {
							opacity: 0;
							transform: translate(110%, -15px);
						}
						to {
							opacity: 1;
							transform: translate(120%, -15px);
						}
					}

					@media only screen and ${device.laptop} {
						transform: translate(350%, -15px);
						animation-duration: 1s;

						@keyframes grown-left {
							from {
								opacity: 0;
								transform: translate(450%, -7.5px);
							}
							to {
								opacity: 1;
								transform: translate(350%, -7.5px);
							}
						}
					}

					@media only screen and ${device.laptopL} {
						transform: translate(450%, -15px);
						animation-duration: 3s;

						@keyframes grown-left {
							from {
								opacity: 0;
								transform: translate(600%, -15px);
							}
							to {
								opacity: 1;
								transform: translate(450%, -15px);
							}
						}
					}

					.carousel--item__circle{
						display: block;
						min-width: 140px;
						min-height: 140px;
						opacity: 0.5;
						margin: 0 0 18px 0;

						@media only screen and ${device.laptop} {
							min-width: calc(277px - (277px / ${mixins.indicator}));
							min-height: calc(277px - (277px / ${mixins.indicator}));
							width: calc(277px - (277px / ${mixins.indicator}));
							height: calc(277px - (277px / ${mixins.indicator}));
						}

						@media only screen and ${device.laptopL} {
							min-width: calc(277px - (277px / ${mixins.indicator}));
							min-height: calc(277px - (277px / ${mixins.indicator}));
							width: 277px;
							height: 277px;
						}

						&:after {
							content: '';
							width: 50%;
							height: 1px;
							background-color: ${colors.white};
							opacity: 1;
							position: absolute;
							z-index: 1;
							top: 35%;
							left: 100%;
							opacity: 0;
							animation-name: appear-line-zero;
							animation-duration: 6s;
							animation-fill-mode: forwards;

							@media only screen and ${device.laptop} {
								left: 83%;
								width: 150%;
								top: 45%;
								transform: translate(0, 50%);

								@keyframes appear-line-zero {
									from {
										opacity: 0;
										width: 50%;
									}
									to {
										opacity: 1;
										width: 150%;
									}
								}
							}

							@media only screen and ${device.laptopL} {
								left: 83%;
								top: 50%;
								transform: translate(0, -50%);

								@keyframes appear-line-zero {
									from {
										opacity: 0;
										width: 50%;
									}
									to {
										opacity: 1;
										width: 100%;
									}
								}
							}
						}

						.carousel--item__rating{
							width: 42px;
							height: 42px;
							top: 45px;
							right: -10px;

							@media only screen and ${device.laptop} {
								width: calc(83px - (83px / ${mixins.indicator}));
								height: calc(83px - (83px / ${mixins.indicator}));
								top: calc(10px - (10px / ${mixins.indicator}));
								right: calc(60px - (60px / ${mixins.indicator}));
							}

							@media only screen and ${device.laptopL} {
								width: 83px;
								height: 83px;
								top: 170px;
								right: 60px;
							}

							p{
								font-size: 1.44rem;

								@media only screen and ${device.laptop} {
									font-size: 2.88rem;
								}
							}
							span{
								font-size: 0.72rem;
								@media only screen and ${device.laptop} {
									font-size: 1.4rem;
								}
							}
						}
					}

					.carousel--item__text {
						position: absolute;
						bottom: -50px;
						left: 50%;
						width: 100%;
						transform: translate(-50%, 0);
						height: 100px;
						opacity: 0.5;

						@media only screen and ${device.laptop} {
							bottom: calc(-165px - (-165px / ${mixins.indicator}));
							height: calc(240px - (240px / ${mixins.indicator}));
							width: 120%;
						}

						@media only screen and ${device.laptopL} {
							bottom: -40px;
							height: 240px;
							width: 120%;
						}
					}

					h3{
						font-size: 1.63rem;
						@media only screen and ${device.laptop} {
							font-size: 3.36rem;
						}
					}
					span{
						font-size: 1.2rem;
						@media only screen and ${device.laptop} {
							font-size: 2.4rem;
						}
					}
				}
			}

			&.sibling_one{
				overflow: visible;
				z-index: 2;
				min-height: 315px;

				>div {
					left: 50%;
					top: 50%;
					transform: translate(-120%, -15px);
					z-index: 1;
					transition: all 1 linear;
					animation-name: grown-right;
					animation-duration: 1s;
					animation-fill-mode: forwards;
					opacity: 0;

					@keyframes grown-right {
						from {
							opacity: 0;
							transform: translate(-100%, -15px);
						}
						to {
							opacity: 1;
							transform: translate(-120%, -15px);
						}
					}

					@media only screen and ${device.laptop} {
						transform: translate(-350%, -14.5px);
						animation-duration: 1s;

						@keyframes grown-right {
							from {
								opacity: 0;
								transform: translate(-450%, -7.5px);
							}
							to {
								opacity: 1;
								transform: translate(-350%, -7.5px);
							}
						}
					}

					@media only screen and ${device.laptopL} {
						transform: translate(-450%, -15px);
						animation-duration: 3s;

						@keyframes grown-right {
							from {
								opacity: 0;
								transform: translate(-600%, -15px);
							}
							to {
								opacity: 1;
								transform: translate(-450%, -15px);
							}
						}
					}

					.carousel--item__circle{
						min-width: 140px;
						min-height: 140px;
						opacity: 0.5;
						margin: 0 0 18px 0;

						@media only screen and ${device.laptop} {
							min-width: calc(277px - (277px / ${mixins.indicator}));
							min-height: calc(277px - (277px / ${mixins.indicator}));
							width: calc(277px - (277px / ${mixins.indicator}));
							height: calc(277px - (277px / ${mixins.indicator}));
						}

						@media only screen and ${device.laptopL} {
							min-width: calc(277px - (277px / ${mixins.indicator}));
							min-height: calc(277px - (277px / ${mixins.indicator}));
							width: 277px;
							height: 277px;
						}

						&:after {
							content: '';
							width: 50%;
							height: 1px;
							background-color: ${colors.white};
							opacity: 1;
							position: absolute;
							z-index: 1;
							top: 35%;
							right: 100%;
							opacity: 0;
							animation-name: appear-line-one;
							animation-duration: 6s;
							animation-fill-mode: forwards;

							@media only screen and ${device.laptop} {
								right: 83%;
								width: 150%;
								top: 45%;
								transform: translate(0, -50%);

								@keyframes appear-line-one {
									from {
										opacity: 0;
										width: 50%;
									}
									to {
										opacity: 1;
										width: 150%;
									}
								}
							}

							@media only screen and ${device.laptopL} {
								right: 83%;
								top: 50%;
								transform: translate(0, -50%);

								@keyframes appear-line-one {
									from {
										opacity: 0;
										width: 50%;
									}
									to {
										opacity: 1;
										width: 100%;
									}
								}
							}
						}

						.carousel--item__rating{
							width: 42px;
							height: 42px;
							top: 45px;
							right: -10px;

							@media only screen and ${device.laptop} {
								width: calc(83px - (83px / ${mixins.indicator}));
								height: calc(83px - (83px / ${mixins.indicator}));
								top: calc(10px - (10px / ${mixins.indicator}));
								right: calc(60px - (60px / ${mixins.indicator}));
							}

							@media only screen and ${device.laptopL} {
								width: 83px;
								height: 83px;
								top: 170px;
								right: 60px;
							}

							p{
								font-size: 1.44rem;

								@media only screen and ${device.laptop} {
									font-size: 2.88rem;
								}
							}
							span{
								font-size: 0.72rem;

								@media only screen and ${device.laptop} {
									font-size: 1.4rem;
								}
							}
						}
					}

					.carousel--item__text {
						position: absolute;
						bottom: -50px;
						left: 50%;
						width: 100%;
						transform: translate(-50%, 0);
						height: 100px;
						opacity: 0.5;

						@media only screen and ${device.laptop} {
							bottom: calc(-165px - (-165px / ${mixins.indicator}));
							height: calc(240px - (240px / ${mixins.indicator}));
							width: 120%;
						}

						@media only screen and ${device.laptopL} {
							bottom: -40px;
							height: 240px;
							width: 120%;
						}
					}

					h3{
						font-size: 1.63rem;
						@media only screen and ${device.laptop} {
							font-size: 3.36rem;
						}
					}
					span{
						font-size: 1.2rem;
						@media only screen and ${device.laptop} {
							font-size: 2.4rem;
						}
					}
				}
			}
		}

		li[aria-hidden="false"]{
			opacity: 1;
		}

		.carousel-button-group{
			width: 100%;
			max-width: 70%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			z-index: 3;
			${flex.centerdr}
			align-items: center;
			${mixins.box}
			margin: 0 auto;

			img {
				opacity: 0;
			}

			@media only screen and ${device.laptop} {
				img{
					width: 25%;
					height: 25%;
				}
			}

			@media only screen and ${device.laptopL} {
				img{
					width: 25%;
					height: 30%;
				}
			}
		}

		.react-multiple-carousel__arrow--right {
			right: calc(12% + 1px);
			background-color: transparent;
			background-image: url(${arrowbutton});
			background-size: cover;
			transform: rotate(-90deg);
			opacity: 0;
			&:before{
				display: none;
			}

			@media only screen and ${device.laptop} {
				right: calc(20% + 1px);

				&.react-multiple-carousel__arrow {
					transform: scale(6) rotate(-90deg);
				}
			}

			@media only screen and ${device.laptopL} {
				right: calc(22% + 1px);

				&.react-multiple-carousel__arrow {
					transform: scale(8) rotate(-90deg);
				}
			}
		}

		.react-multiple-carousel__arrow--left {
			left: calc(12% + 1px);
			background-color: transparent;
			background-image: url(${arrowbutton});
			background-size: cover;
			transform: rotate(90deg);
			opacity: 0;
			&:before{
				display: none;
			}

			@media only screen and ${device.laptop} {
				left: calc(20% + 1px);

				&.react-multiple-carousel__arrow {
					transform: scale(6) rotate(90deg);
				}
			}

			@media only screen and ${device.laptopL} {
				left: calc(22% + 1px);

				&.react-multiple-carousel__arrow {
					transform: scale(8) rotate(90deg);
				}
			}
		}
	}

	.react-multi-carousel-dot-list {
		bottom: 50px;
		z-index: 5;
		display: flex;

		li[data-index="0"]{
			order:7;
		}

		li[data-index="1"]{
			order:0;
		}

		li[data-index="2"]{
			order:1;
		}

		li[data-index="3"]{
			order:2;
		}

		li[data-index="4"]{
			order:3;
		}

		li[data-index="5"]{
			order:4;
		}

		li[data-index="6"]{
			order:5;
		}

		li[data-index="7"]{
			order:6;
		}

		@media only screen and ${device.laptop} {
			bottom: 10px;
		}

		@media only screen and ${device.laptopL} {
			bottom: 10px;
		}
	}

	.react-multi-carousel-dot button {
		border: none;
		background-color: ${colors.creamLight};

		@media only screen and ${device.laptop} {
			width: 6px;
    	height: 6px;
			margin-right: 7.5px;
		}

		@media only screen and ${device.laptopL} {
			width: 12px;
    	height: 12px;
			margin-right: 15px;
		}
	}

	.react-multi-carousel-dot--active button {
		background-color: ${colors.yellow};
	}
`
export const AwardsStyles = createGlobalStyle`
	.awards{
		${flex.centerdc}
		${mixins.box}
		padding: 0 ${gap.gap17} 0;
		margin: 0;

		@media only screen and ${device.laptop} {
			${flex.centerdr}
			width: 100%;
			padding: 0 105.4px 0 80px;
			margin: 0 0;
			max-height: 472.5px;
			max-width: 100%;
		}

		@media only screen and ${device.laptopL} {
			${flex.centerdr}
			width: 100%;
			padding: 0 217px 0 160px;
			margin: 0 0;
			max-height: 945px;
			max-width: 100%;
		}

		&--type{
			background-color: rgba(5, 6, 9, 0.65);
			border-radius: 8px;
			margin: 0 0 calc(${gap.gap40} * 2);
			padding: 0 ${gap.gap10} ${gap.gap30};
			position: relative;

			&:last-of-type{
				margin: 0 0 calc(${gap.gap20} * 2);

				@media only screen and ${device.laptop} {
					margin: 0 0 calc(${gap.gap10} * 2);
				}

				@media only screen and ${device.laptopL} {
					margin: 0 0 calc(${gap.gap20} * 2);
				}
			}

			@media only screen and ${device.laptop} {
				&__tv {
					margin: 119px 0 0 0;
					min-width: 170px;
					min-height: 100px;
					max-height: calc(230px - (230px / ${mixins.indicator}));
					width: 25%;
					height: auto;
				}

				&__india {
					min-width: 170px;
					min-height: 175px;
					max-height: calc(320px - (320px / ${mixins.indicator}));
					width: 25%;
					height: auto;
				}
			}

			@media only screen and ${device.laptopL} {
				&__tv {
					margin: 238px 0 0 0;
					min-width: 340px;
					min-height: 130px;
					max-height: 230px;
					width: 25%;
					height: auto;
				}

				&__india {
					min-width: 340px;
					min-height: 180px;
					max-height: 280px;
					width: 25%;
					height: auto;
				}

				&:last-of-type{
					margin: 0 0 calc(${gap.gap20} * 2) 0;
				}

			}

			h3{
				${fonts.font_00}
				color: ${colors.yellow};
				padding: ${gap.gap25} 0 ${gap.gap25} ${gap.gap120};
				margin: 0;
				font-size: 2.4rem;
				${fonts.font_normal}

				@media only screen and ${device.laptop} {
					padding: 12.5px 0 12.5px ${gap.gap50};
				}

				@media only screen and ${device.laptopL} {
					padding: ${gap.gap25} 0 ${gap.gap25} ${gap.gap120};
				}
			}

			ul{
				margin: 0;
				padding: 0 0 0 ${gap.gap5};
				list-style: none;

				@media only screen and ${device.laptop} {
					padding: 0 0 0 2.5px;
				}

				@media only screen and ${device.laptopL} {
					padding: 0 0 0 ${gap.gap5};
				}
			}

			p{
				text-align: left;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				font-size. 1.6rem;
				line-height: 2.8rem;

				svg{
					margin: 0 ${gap.gap10} 0 0;

					@media only screen and ${device.laptop} {
						margin: 0 ${gap.gap5} 0 0;
					}

					@media only screen and ${device.laptop} {
						margin: 0 ${gap.gap10} 0 0;
					}
				}
			}

			&__text{
				padding: 0 0 0 ${gap.gap20};
				position: relative;
				&:before {
					content: '';
					display: inline-block;
					background-color: ${colors.yellow};
					width: 3px;
					height: 3px;
					border-radius: 50%;
					position: absolute;
					left: 7px;
					top: 12px;

					@media only screen and ${device.laptop} {
						width: 1.5px;
						height: 1.5px;
						left: 8px;
						top: 7.5px;
					}

					@media only screen and ${device.laptopL} {
						width: 3px;
						height: 3px;
						left: 7px;
						top: 15px;
					}
				}
			}

			&__award{
				${mixins.box}
				${flex.centerdc}
				align-items: center;
				position: absolute;
				left: 10px;
				top: -55px;
				width: 100px;

				@media only screen and ${device.laptop} {
					left: 5px;
					top: -25.5px;
					width: 50px;
				}

				@media only screen and ${device.laptopL} {
					left: 10px;
					top: -55px;
					width: 100px;
				}
			}
		}
	}
`

export const ProtagonistasStyles = createGlobalStyle`
	.protagonistas{
		${flex.centerdc}
		${mixins.box}
		padding: 0 ${gap.gap17} 0;
		position: relative;
    z-index: 2;

		@media only screen and ${device.laptop} {
			width: 100%;
			padding: 0 108.5px 0 110.5px;
			margin: 0 auto;
			max-height: 472.5px;
			max-width: 100%;
		}

		@media only screen and ${device.laptopL} {
			width: 100%;
			padding: 0 217px 0 221px;
			margin: 0 0;
			max-height: 945px;
			max-width: 100%;
		}

		&--content{
			position: relative;
			z-index:2;

			@media only screen and ${device.laptop} {
				${flex.centerdr}
				flex: 1 0 0;
				width: 100%;
				max-width: 950px;
				margin: 0 auto;
			}

			@media only screen and ${device.laptopL} {
				max-width: 1700px;
			}

			&__text{
				${mixins.box}
				background-color: ${colors.creamLight};
				padding: ${gap.gap50} ${gap.gap10} ${gap.gap20};
				color: ${colors.dgray};
				font-size: 2.0rem;
				${fonts.font_normal}
				line-height: 1.3;
				text-aling: left;
				margin: 0 0 ${gap.gap10} 0;

				@media only screen and ${device.laptop} {
					${flex.centerdr}
					align-items: flex-end;
					max-width: 275px;
					padding: 12.5px 21px 28px;
					margin: 0 22px 0 0;
					font-size: 2.5rem;
					line-height: normal;
				}

				@media only screen and ${device.laptopL} {
					${flex.centerdr}
					align-items: flex-end;
					max-width: 525px;
					padding: 25px 42px 56px;
					margin: 0 22px 0 0;
					font-size: 2.5rem;
					line-height: normal;
				}
			}

			&__video{
				@media only screen and ${device.laptop} {
					width: 100%;
					max-width: calc(100% - 211.5px);
					height: 100%;
					max-height: 472.5px;
				}

				@media only screen and ${device.laptopL} {
					width: 100%;
					max-width: calc(100% - 423px);
					height: 100%;
					max-height: 945px;
				}
			}
		}
	}

	.section--container__background &{
		position: relative;

		h2{
			position: relative;
			z-index:2;
		}

		&:before{
			content: '';
			display: block;
			width: 100%;
			height: 110%;
			left: 0;
			top: 0;
			position: absolute;
			background: black;
			opacity: .5;
			z-index: 1;
			clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);

			@media only screen and ${device.laptop} {
				margin: 0 0 0 calc(107px - (107px / ${mixins.indicator}));
				height: 120%;
				width: calc(100% - 76px);
			}

			@media only screen and ${device.laptopL} {
				clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
				margin: 0 0 0 107px;
				height: 120%;
				width: calc(100% - 114px);
			}
		}
	}
`
export const TimelineStylesMobile = createGlobalStyle`
	.carousel .slide {
		text-align: left;
	}
	.carousel.carousel-slider .control-arrow{
		opacity: 0;
	}
	.timeline{
		${mixins.box}
		position: relative;
		padding: 0 0 90px;
		margin: 0;
		width: 100vw;

		.carousel{
			.slider-wrapper{
				ul{
					li.slide{
						transform: translate(-35px, 0);
						transition: all 0.2s linear;
						&:after{
							content: '';
							position: absolute;
							margin: 50px 0 0 0;
							width: calc(100% + 17px);
							left: -17px;
							bottom: 24px;
							height: 1px;
							display: block;
							background-color: ${colors.creamLight};
							border-bottom: solid 1px ${colors.creamLight};
						}
						&:before{
							content: '';
							position: absolute;
							left: calc(50% - 17px);
							bottom: 0;
							display: block;
							width: 46px;
							height: 46px;
							z-index: 1;
							background-image: url(${star});
							background-repeat: no-repeat;
							background-position: center center;
							background-position: cover;
						}
						&:first-of-type{
							&:after{
								left: 50%;
								width: calc(100% + 17px);
								max-width: 50%;
							}
						}
						&:last-of-type{
							&:after{
								right: -17px;
								width: calc(100% + 17px);
								max-width: 55%;
							}
						}
						&.previous{
							opacity: 1;
						}
						&.selected{
							opacity: 1;
							transform: translate(0, 0);
						}
					}
				}
			}
		}

		.react-multi-carousel-track{
			position: relative;
			z-index: 2;
			left: -20px;
		}

		&--item{
			padding: 0 0 ${gap.gap50} ${gap.gap17};
			${mixins.box}
			height: auto;
			position: relative;

			>div {
				max-width: calc(100vw - 44px);
				margin: 0;
				padding: 0;
				h3{
					${fonts.font_01}
					font-weight: 600;
					font-size: 3.6rem;
					${fonts.font_normal}
					margin: 0;
					padding: 0;
					min-height: 140px;
				}
				p{
					${fonts.font_01}
					font-size: 1.2rem;
					${fonts.font_normal}
					margin: 0 0 ${gap.gap15};
					padding: 0;
					span{
						${fonts.font_00}
					}
				}
				.timeline--item__circle {
					width: 102px;
					height: 102px;
					box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.6);
					background-image: linear-gradient(to bottom, #b5861c, #e8c56b);
					border-image-slice: 1;
					border-radius: 50%;
					background-color: ${colors.black};
					overflow: hidden;
					position: relative;
					padding: 0;

					.timeline--item__img{
						position: absolute;
						border-radius: 50%;
						overflow: hidden;
						width: 97%;
						height: 97%;
						left: 50%;
						top: 50%;
						transform: translate(-50.2%,-50%);

						img{
							width: 100%;
							height: 100%;
						}
					}
				}
				.timeline--item__text {
					width: auto;
					height: auto;
					${flex.centerdc}
					${mixins.box}
					padding: 0 0 0 ${gap.gap15};
					width: calc(100% - 117px);
					h4{
						${fonts.font_01}
						font-weight: 600;
						font-size: 1.73rem;
						${fonts.font_normal}
						color: ${colors.creamDark};
						margin: 0;
						padding: 0;
					}
					span{
						${fonts.font_00}
						font-size: 2.59rem;
						${fonts.font_normal}
						color: ${colors.creamDark};
						margin: 0;
						padding: 0;
					}
					p{
						${fonts.font_normal}
						font-size: 1.3rem;
						${fonts.font_normal}
						color: ${colors.creamLight};
						margin: 0;
						padding: 0;
					}
				}
				.timeline--item__winner, .timeline--item__cast{
					${flex.center}
					${mixins.box}
					margin: 0 0 ${gap.gap10};
					padding: ${gap.gap17} ${gap.gap25};
					background-color: rgba(${colors.creamRgb}, 0.15);
					border-radius: 4.3px;
					-webkit-backdrop-filter: blur(10.4px);
					backdrop-filter: blur(10.4px);

					.timeline--item__presenters, .timeline--item__juries{
						ul{
							list-style: none;
							padding: 0;
							margin: 0;
							${flex.center}
							${mixins.box}
							justify-content: space-between;
						}
						li{
							max-width: 39px;
							height: 80px;
						}
						p{
							font-size: 0.86rem;
							text-align: center;
							${fonts.font_normal}
							line-height: 1.06;
						}
						h4 {
							${fonts.font_00}
							width: 74px;
							height: 24px;
							padding: 6px 5px 3.7px;
  						border-radius: 2.1px;
							font-size: 0.83rem;
							${fonts.font_normal}
							text-align: center;
							border: solid 0.5px ${colors.creamLight};
							display: block;
							margin: 0 auto;
							background-color: rgba(${colors.creamRgb}, 0.15);
						}
					}

					.timeline--item__presenters {
						width: 40%;
						border-right: solid 0.5px ${colors.creamLight};
						padding: 0 ${gap.gap15} 0 0;
					}

					.timeline--item__juries {
						width: 60%;
						padding: 0 0 0 ${gap.gap15};
					}

					.timeline--item__image {
						width: 40px;
						height: 40px;
						border-radius: 50%;
						border: solid 0.5px ${colors.creamDark};
						margin: 0 0 ${gap.gap5};
						overflow: hidden;
					}
				}
			}
			&.sibling_one{
				left: -36px;
    		opacity: 1;
			}
			&.react-multi-carousel-item--active{
				left: 0;
    		opacity: inherit;
			}
			&.react-multi-carousel-item--active + li {
				left: -36px;
				opacity: 1;
			}
		}
	}
`

export const TimelineStylesDesktop = createGlobalStyle`
	.timeline{
		${mixins.box}
		position: relative;
		width: 100%;
		margin: 0 auto;
		display: grid;
		grid-template-columns: minmax(50%, auto) minmax(50%, auto);
		gap: 0;
		-ms-grid-columns: minmax(50%, auto) minmax(50%, auto);
		-ms-column-gap: 0;
		margin: 0 0;
		max-width: 100%;

		@media only screen and ${device.laptop} {
			padding: 0 calc(217px / ${mixins.indicator}) 0 calc(221px - (221px / ${mixins.indicator}));
		}
		@media only screen and ${device.laptopL} {
			padding: 0 217px 0 221px;
		}

		.react-multi-carousel-track{
			position: relative;
			z-index: 2;
		}

		&--item{
			${mixins.box}
			height: auto;
			position: relative;
			${flex.center}
			justify-content: center;
			padding: 0;
			margin: 0 auto;

			>div {
				width: 100%;

				@media only screen and ${device.laptop} {
					margin: 0 auto calc(50px - (50px / ${mixins.indicator}));
				}
				@media only screen and ${device.laptopL} {
					margin: 0 auto 100px;
				}

				&.left, &.right{

					@media only screen and ${device.laptop} {
						max-width: calc(618px - (618px / ${mixins.indicator}));
					}

					@media only screen and ${device.laptopL} {
						max-width: 618px;
					}
				}

				&.left{
					h3, p{
						text-align: right;
					}
				}

				&.right{
					h3, p{
						text-align: left;
					}
				}
			}

			&.left{
				border-right: solid 1px ${colors.creamLight};

				&:before{
					content: '';
					position: absolute;
					left: inherit;
					top: -5px;
					bottom: inherit;
					display: block;
					z-index: 1;
					background-image: url(${starbig});
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 99% auto;

					@media only screen and ${device.laptop} {
						right: calc(-46px - (-46px / ${mixins.indicator}));
						width: calc(92px - (92px / ${mixins.indicator}));
						height: calc(92px - (92px / ${mixins.indicator}));
					}

					@media only screen and ${device.laptopL} {
						right: -46px;
						width: 92px;
						height: 92px;
					}
				}
			}

			&.right{
				border-left: solid 1px ${colors.creamLight};

				&:before{
					content: '';
					position: absolute;
					right: inherit;
					top: 0;
					bottom: inherit;
					display: block;
					z-index: 1;
					background-image: url(${starbig});
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 99% auto;

					@media only screen and ${device.laptop} {
						left: calc(-46px - (-46px / ${mixins.indicator}));
						width: calc(92px - (92px / ${mixins.indicator}));
						height: calc(92px - (92px / ${mixins.indicator}));
					}

					@media only screen and ${device.laptopL} {
						left: -46px;
						width: 92px;
						height: 92px;
					}
				}
			}

			&:last-of-type{
				&.left{
					border-right: none;
				}
				&.right{
					border-left: none;
				}
			}
		}

		>div {
			margin: 0;
			padding: 0;

			@media only screen and ${device.laptop} {
				max-width: calc(100vw - 33px);
			}

			@media only screen and ${device.laptopL} {
				max-width: calc(100vw - 44px);
			}
			h3{
				${fonts.font_01}
				font-weight: 600;
				font-size: 3.6rem;
				${fonts.font_normal}
				margin: 0;
				padding: 0;

				@media only screen and ${device.laptop} {
					font-size: 7.2rem;
				}
			}

			p{
				${fonts.font_01}
				font-size: 1.2rem;
				${fonts.font_normal}
				margin: 0 0 ${gap.gap15};
				padding: 0;

				@media only screen and ${device.laptop} {
					margin: 0 0 calc(${gap.gap15} - (${gap.gap15} / ${mixins.indicator}));
				}

				@media only screen and ${device.laptopL} {
					margin: 0 0 ${gap.gap15};
				}

				span{
					${fonts.font_00}
				}

				@media only screen and ${device.laptop} {
					font-size: 2.4rem;
					margin: 0 0 calc(${gap.gap15} - (${gap.gap15} / ${mixins.indicator}));
				}

				@media only screen and ${device.laptopL} {
					font-size: 2.4rem;
					margin: 0 0 ${gap.gap15};
				}
			}
			.timeline--item__circle {
				${flex.center}
				${mixins.box}
				box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.6);
				background-image: linear-gradient(to bottom, #b5861c, #e8c56b);
				border-image-slice: 1;
				border-radius: 50%;
				background-color: ${colors.black};
				overflow: hidden;
				position: relative;
				padding: 0;

				.timeline--item__img{
					position: absolute;
					border-radius: 50%;
					overflow: hidden;
					width: 98%;
					height: 98%;
					left: 50%;
					top: 50%;
					transform: translate(-50.2%,-50%);

					img{
						width: 100%;
						height: 100%;
					}
				}

				@media only screen and ${device.laptop} {
					width: calc(189px - (189px / ${mixins.indicator}));
					height: calc(189px - (189px / ${mixins.indicator}));
					margin: 0 0 calc(10px - (10px / ${mixins.indicator}));
				}

				@media only screen and ${device.laptopL} {
					width: 189px;
					height: 189px;
					margin: 0 0 10px;
				}
			}

			.timeline--item__text {
				width: auto;
				height: auto;
				${flex.centerdc}
				${mixins.box}

				@media only screen and ${device.laptop} {
					padding: 0 0 0 calc(${gap.gap15} - (${gap.gap15} / ${mixins.indicator}));
					width: calc(100% - 120px);
				}

				@media only screen and ${device.laptopL} {
					padding: 0 0 0 ${gap.gap15};
					width: calc(100% - 194px);
				}

				h4{
					${fonts.font_01}
					font-weight: 600;
					font-size: 3.2rem;
					${fonts.font_normal}
					color: ${colors.creamDark};
					margin: 0;
					padding: 0;
				}
				span{
					${fonts.font_00}
					font-size: 4.8rem;
					${fonts.font_normal}
					color: ${colors.creamDark};
					margin: 0;
					padding: 0;
				}

				p{
					${fonts.font_normal}
					font-size: 2.4rem;
					${fonts.font_normal}
					color: ${colors.creamLight};
					margin: 0;
					padding: 0;
					text-align: left;
				}
			}

			.timeline--item__winner, .timeline--item__cast{
				${flex.center}
				${mixins.box}
				background-color: rgba(${colors.creamRgb}, 0.15);
				border-radius: 4.3px;
				-webkit-backdrop-filter: blur(10.4px);
				backdrop-filter: blur(10.4px);

				@media only screen and ${device.laptop} {
					margin: 0 0 calc(${gap.gap10} - (${gap.gap10} / ${mixins.indicator}));
					padding: calc(33px - (33px / ${mixins.indicator})) calc(22px - (22px / ${mixins.indicator}));
				}

				@media only screen and ${device.laptopL} {
					margin: 0 0 ${gap.gap10};
					padding: 33px 45px;
				}

			.timeline--item__presenters, .timeline--item__juries{
				ul{
					list-style: none;
					padding: 0;
					margin: 0;
					${flex.center}
					${mixins.box}
					align-items: flex-start;
					justify-content: space-between;
				}
				li{
					@media only screen and ${device.laptop} {
						max-width: calc(72px - (72px / ${mixins.indicator}));
						height: 100px;
					}

					@media only screen and ${device.laptopL} {
						max-width: 72px;
						height: 140px;
					}
				}
				p{
					font-size: 1.6rem;
					text-align: center;
					${fonts.font_normal}
					line-height: 1.06;

					@media only screen and ${device.laptop} {
						margin: 0 0 calc(24px - (24px / ${mixins.indicator}));
					}

					@media only screen and ${device.laptopL} {
						margin: 0 0 24px;
					}
				}
				h4 {
					${fonts.font_00}
  				border-radius: 2.1px;
					font-size: 1.54rem;
					${fonts.font_normal}
					text-align: center;
					border: solid 0.5px ${colors.creamLight};
					display: block;
					margin: 0 auto;
					background-color: rgba(${colors.creamRgb}, 0.15);

					@media only screen and ${device.laptop} {
						width: calc(137px - (137px / ${mixins.indicator}));
						height: calc(44px - (44px / ${mixins.indicator}));
						padding: calc(11px - (11px / ${mixins.indicator})) calc(9px - (9px / ${mixins.indicator})) calc(6.6px - (6.6px / ${mixins.indicator})) calc(10px - (10px / ${mixins.indicator}));
					}

					@media only screen and ${device.laptopL} {
						width: 137px;
						height: 44px;
						padding: 11px 9px 6.6px 10px;
					}
					}
				}

				.timeline--item__presenters {
					width: 40%;
					border-right: solid 0.5px ${colors.creamLight};


					@media only screen and ${device.laptop} {
						padding: 0 calc(44px - (44px / ${mixins.indicator})) 0 0;
					}

					@media only screen and ${device.laptopL} {
						padding: 0 44px 0 0;
					}
				}

				.timeline--item__juries {
					width: 60%;

					@media only screen and ${device.laptop} {
						padding: 0 0 0 calc(44px - (44px / ${mixins.indicator}));
					}

					@media only screen and ${device.laptopL} {
						padding: 0 0 0 44px;
					}
				}

				.timeline--item__image {
					border-radius: 50%;
					border: solid 0.5px ${colors.creamDark};
					margin: 0 0 ${gap.gap5};
					overflow: hidden;

					@media only screen and ${device.laptop} {
						width: calc(72px - (72px / ${mixins.indicator}));
						height: calc(72px - (72px / ${mixins.indicator}));
						margin: 0 0 calc(${gap.gap5} - (${gap.gap5} / ${mixins.indicator}));
					}

					@media only screen and ${device.laptopL} {
						width: 72px;
						height: 72px;
						margin: 0 0 ${gap.gap5};
					}
				}
			}
		}
	}
`

export const GalleryMobileStyles = createGlobalStyle`
	.gallery{
		${mixins.box}
		position: relative;
		padding: 0;
		margin: 0;

		.carousel{
			.slider-wrapper{
				ul{
					li.slide{
						transform: translate(-35px, 0);
						transition: all 0.2s linear;
						&.previous{
							opacity: 1;
						}
						&.selected{
							opacity: 1;
							transform: translate(0, 0);
						}
					}
				}
			}
		}

		&--list__content {
			width: 100%;
			height: 28px;
			padding: 0;
			overflow: hidden;
			margin: 0 0 ${gap.gap30};
		}

		&--list{
			${flex.left}
			${mixins.box}
			margin: 0 0 ${gap.gap30};
			padding: 0 0 0 ${gap.gap17};
			list-style: none;
			overflow-y: hidden;
			width: 100vw;
			height: 40px;

			&__item{
				${flex.centerdc}
				${mixins.box}
				align-items: center;
				margin: 0 0 0 calc(${gap.gap10} - 3px);
				padding: 0;
				width: auto;
				min-width: 80px;
  			height: 26px;
				border-radius: 2.2px;
				border: solid 0.5px ${colors.creamDark};
  			background-color: rgba(255, 244, 226, 0.1);
				font-size: 0.9rem;
				font-family: ${fonts.font_00};
				opacity: 0.6;

				&:first-of-type{
					margin: 0;
				}

				&.react-tabs__tab--selected {
					border: solid 0.5px ${colors.creamDark};
  				background-color: ${colors.creamLight};
					color: #212121;
					opacity: 1;
				}
			}
		}

		&__item{

		}
	}

	.pictures{
		${mixins.box}
		position: relative;
		padding: 0;
		margin: 0;

		.react-multi-carousel-track{
			position: relative;
			z-index: 2;
		}

		&--item{
			padding: 0 0 0 ${gap.gap17};
			${mixins.box}
			height: auto;
			>div {
				max-width: calc(100vw - 44px);
				margin: 0;
				padding: 0;
				.pictures--item__img {
					${flex.center}
					${mixins.box}
					margin: 0 0 ${gap.gap10};
					padding: 0;
					border-radius: 4.3px;
					position: relative;
					max-height: 50vw;
    			overflow: hidden;
					align-items: flex-start;

					button {
						padding: 0;
						margin: 0;
						border: none;
						background: none;

						img{
							border: none;
							margin: 0;
							padding: 0;
							display: block;
							min-width: 331px;
							min-height: 187px;
						}

						&:before{
							content: '';
							width: 100%;
							height: 100%;
							position: absolute;
							left: 0;
							top: 0;
							background-color: ${colors.black};
							z-index: 3;
							opacity: 0.2;
							transition: opacity 1s ease-in;
						}

						&:hover{
							&:before{
								opacity: 0;
							}
						}
					}

					&:before{
						position: absolute;
						content: '';
						width: 63px;
						height: 63px;
						border-radius: 50%;
						padding: 14px 15px 15px 14px;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						background-blend-mode: multiply;
						background-image: linear-gradient(to bottom, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7));
					}

					&:after{
						position: absolute;
						content: '';
						width: 63px;
						height: 63px;
						border-radius: 50%;
						padding: 14px 15px 15px 14px;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						background-image: url(${camera});
						background-repeat: no-repeat;
						background-position: center center;
						background-size: 34px 29px;
					}
				}
			}

			&.react-multi-carousel-item--active{
				left: 0;
				opacity: 1;
			}
			&.react-multi-carousel-item--active + li {
				left: -36px;
				opacity: 1;
			}
		}
	}

	.ril-image-current {
		transform: none !important;
	}

	.ril__toolbarRightSide {
    padding-right: 0;
	}
`
export const GalleryDesktopStyles = createGlobalStyle`
	.gallery{
		${mixins.box}
		position: relative;padding: 0 0 ${gap.gap60};
		margin: 0;
		z-index: 2;

		@media only screen and ${device.laptop} {
			padding: 0 0 calc(${gap.gap120} - (${gap.gap120} / ${mixins.indicator}));
		}

		@media only screen and ${device.laptopL} {
			padding: 0 0 ${gap.gap120};
		}

		&--list__content {
			width: 100%;
			height: auto;

			@media only screen and ${device.laptop} {
				min-height: calc(58px - (58px / ${mixins.indicator}));
				padding: 0;
				margin: 0 0 calc(30px - (30px / ${mixins.indicator}));
			}

			@media only screen and ${device.laptopL} {
				min-height: 58px;
				padding: 0;
				margin: 0 0 60px;
			}
		}

		&--list{
			${flex.centerdr}
			${mixins.box}
			justify-content: center;
			margin: 0 auto ${gap.gap30};
			padding: 0 0 0 ${gap.gap17};
			list-style: none;
			width: 100%;
			height: auto;

			@media only screen and ${device.laptop} {
				margin: 0 auto calc(30px - (30px / ${mixins.indicator}));
				padding: 0 0 0 calc(${gap.gap17} - (${gap.gap17} / ${mixins.indicator}));
				min-height: calc(58px - (58px / ${mixins.indicator}));
				max-width: calc(1500px - (1500px / ${mixins.indicator}));
			}

			@media only screen and ${device.laptopL} {
				margin: 0 auto ${gap.gap30};
				padding: 0 0 0 ${gap.gap17};
				min-height: 58px;
				max-width: 1500px;
			}

			&__item{
				${flex.centerdc}
				${mixins.box}
				align-items: center;
 				border-radius: 4.8px;
				border: solid 0.5px ${colors.creamDark};
  			background-color: rgba(255, 244, 226, 0.1);
				font-size: 1.98rem;
				font-family: ${fonts.font_00};
				opacity: 0.6;
				cursor: pointer;

				@media only screen and ${device.laptop} {
					margin: 0 0 0 calc(26px - (26px / ${mixins.indicator}));
					padding: 0 calc(12px - (12px / ${mixins.indicator})) 0 calc(13px - (13px / ${mixins.indicator}));
					width: calc(172px - (172px / ${mixins.indicator}));
					height: auto;
					min-height: calc(29px - (29px / ${mixins.indicator}));
				}

				@media only screen and ${device.laptopL} {
					margin: 0 0 0 52px;
					padding: 0 12px 0 13px;
					width: 172px;
					height: auto;
					min-height: 58px;
				}

				&:first-of-type{
					margin: 0;
				}

				&.react-tabs__tab--selected {
					border: solid 0.5px ${colors.creamDark};
  				background-color: ${colors.creamLight};
					color: #212121;
					opacity: 1;
				}
			}
		}
	}

	.pictures{
		${mixins.box}
		position: relative;
		padding: 0;
		margin: 0 auto;

		@media only screen and ${device.laptop} {
			max-width: calc(1700px - (1700px / ${mixins.indicator}));
		}

		@media only screen and ${device.laptopL} {
			max-width: 1700px;
		}

		.react-multi-carousel-track{
			position: relative;
			z-index: 2;
		}

		&--item{
			padding: 0 0 0 ${gap.gap17};
			${mixins.box}
			height: auto;

			@media only screen and ${device.laptop} {
				padding: 0 0 0 calc(${gap.gap17} - (${gap.gap17} / ${mixins.indicator}));
			}

			@media only screen and ${device.laptopL} {
				padding: 0 0 0 ${gap.gap17};
			}

			>div {
				margin: 0;
				padding: 0;

				@media only screen and ${device.laptop} {
					max-width: calc(100vw - 44px);
				}

				@media only screen and ${device.laptopL} {
					max-width: calc(100vw - 44px);
				}

				display: grid;

				@media only screen and ${device.laptop} {
					grid-template-columns: minmax(calc(33% - 16.5px), auto) minmax(calc(33% - 16.5px), auto) minmax(calc(33% - 16.5px), auto);
					gap: 25px;
					-ms-grid-columns: minmax(calc(33% - 16.5px), auto) minmax(calc(33% - 16.5px), auto) minmax(calc(33% - 16.5px), auto);
					-ms-column-gap: 25px;
				}

				@media only screen and ${device.laptopL} {
					grid-template-columns: minmax(calc(33% - 33px), auto) minmax(calc(33% - 33px), auto) minmax(calc(33% - 33px), auto);
					gap: 50px;
					-ms-grid-columns: minmax(calc(33% - 33px), auto) minmax(calc(33% - 33px), auto) minmax(calc(33% - 33px), auto);
					-ms-column-gap: 50px;
				}

				.pictures--item__img {
					${flex.center}
					${mixins.box}
					align-items: flex-start;
					margin: 0;
					padding: 0;
					border-radius: 4.3px;
					position: relative;
					transition: all .2s linear;
					opacity: 0.4;
					border: solid 2px ${colors.black};
					max-height: 15vw;
    			overflow: hidden;

					@media only screen and ${device.laptop} {
						max-height: 15vw;
					}

					@media only screen and ${device.laptopL} {
						max-height: 12vw;
					}

					&:hover{
						opacity: 1;
						border: solid 2px ${colors.yellow};
					}

					button {
						padding: 0;
						margin: 0;
						border: none;
						background: none;

						img{
							border: none;
							margin: 0;
							padding: 0;
							display: block;

							@media only screen and ${device.laptop} {
								min-width: calc(505px - (505px / ${mixins.indicator}));
								min-height: calc(284px - (284px / ${mixins.indicator}));
							}

							@media only screen and ${device.laptopL} {
								min-width: 505px;
								min-height: 284px;
							}
						}

						&:before{
							content: '';
							width: 100%;
							height: 100%;
							position: absolute;
							left: 0;
							top: 0;
							background-color: ${colors.black};
							z-index: 3;
							opacity: 0.2;
							transition: opacity 1s ease-in;
							cursor: pointer;
						}

						&:hover{
							&:before{
								opacity: 0;
							}
						}
					}

					&:before{
						position: absolute;
						content: '';
						border-radius: 50%;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						background-blend-mode: multiply;
						background-image: linear-gradient(to bottom, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7));

						@media only screen and ${device.laptop} {
							width: 47.5px;
							height: 47.5px;
							padding: 7px 7.5px 7.5px 7px;
						}

						@media only screen and ${device.laptopL} {
							width: 95px;
							height: 95px;
							padding: 14px 15px 15px 14px;
						}
					}

					&:after{
						position: absolute;
						content: '';
						border-radius: 50%;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						background-image: url(${camerabig});
						background-repeat: no-repeat;
						background-position: center;

						@media only screen and ${device.laptop} {
							width: 47.5px;
							height: 47.5px;
							padding: 7px 7.5px 7.5px 7px;
							background-size: 25.5px 22.5px;
						}

						@media only screen and ${device.laptopL} {
							width: 95px;
							height: 95px;
							padding: 14px 15px 15px 14px;
							background-size: 51px 45px;
						}
					}
				}
			}

			&.react-multi-carousel-item--active{
				left: 0;
    		opacity: inherit;
			}
		}
	}

	.ril-image-current {
		transform: none !important;
	}

	.ril__toolbarRightSide {
    padding-right: 0;

		@media only screen and ${device.laptop} {
			padding-right: 10px;
		}
	}
`
export const Cast = createGlobalStyle`
	.cast{
		margin: 0;
		padding: 0;

		@media only screen and ${device.laptop} {
			${flex.center}
			${mixins.box}
			align-items: center;
			justify-content: center;
			max-width: 1700px;
			margin: 0 auto;
		}


		@media only screen and ${device.laptopL} {
			${flex.center}
			${mixins.box}
			align-items: center;
			justify-content: center;
			max-width: 1700px;
			margin: 0 auto;
		}

		&--logo{
			width: 100%;
			max-width: 250px;
			height: auto;
			display: block;
			margin: 0 auto ${gap.gap25};

			@media only screen and ${device.laptop} {
				max-width: 320.5px;
				position: absolute;
				left: 50%;
				right: 50%;
				transform: translate(-50%,-50%);
				margin: 0;
				padding: 0;
				z-index: 2;
				img{
					display: block;
					margin: 0 auto 23.5px;
					max-width: 200px;
				}
			}

			@media only screen and ${device.laptopL} {
				max-width: 641px;
				position: absolute;
				left: 50%;
				right: 50%;
				transform: translate(-50%,-50%);
				margin: 0;
				padding: 0;
				z-index: 2;
				img{
					display: block;
					margin: 0 auto 47px;
					max-width: 400px;
				}
			}
		}

		h3{
			${fonts.font_00}
			padding: 0;
			margin: 0 auto ${gap.gap5};
			font-size: 1.6rem;
			${fonts.font_normal}
			text-align: center;

			@media only screen and ${device.laptop} {
				font-size: 3.2rem;
				margin: 0 auto 23.5px;
			}

			@media only screen and ${device.laptopL} {
				font-size: 3.2rem;
				margin: 0 auto 47px;
			}
		}

		h4{
			${fonts.font_00}
			padding: 0;
			margin: 0 auto ${gap.gap70};
			font-size: 2.8rem;
			${fonts.font_normal}
			line-height: 1.14;
			text-align: center;

			@media only screen and ${device.laptop} {
				font-size: 5.6rem;
				margin: 0 auto 35px;
			}

			@media only screen and ${device.laptopL} {
				font-size: 5.6rem;
				margin: 0 auto ${gap.gap70};
			}
		}

		&--juries{
			width: 100%;
			padding: 0 ${gap.gap25};
			min-height: 666px;
			margin: 0;
			padding: 0;
			${flex.centerdc}
			${mixins.box}

			@media only screen and ${device.laptop} {
				position: relative;
				width: 35%;
				min-height: 80vh;
				height: 100%;
				margin: 0 0 0 5%;
				padding: 0;
				position: relative;
				z-index: 1;
				${flex.right}
				${mixins.box}
				padding: 0 12.5;
				min-height: 333px;
			}

			@media only screen and ${device.laptopL} {
				position: relative;
				width: 35%;
				min-height: 80vh;
				height: 100%;
				margin: 0 0 0 15%;
				padding: 0;
				position: relative;
				z-index: 1;
				${flex.right}
				${mixins.box}
			}
		}

		&--presenters{
			width: 100%;
			padding: 0 ${gap.gap25};
			min-height: 527px;
			margin: 0 0 ${gap.gap5};
			padding: 0;
			${flex.centerdc}
			${mixins.box}

			@media only screen and ${device.laptop} {
				position: relative;
				width: 35%;
				min-height: 80vh;
				height: 100%;
				margin: 0 5% 0 0;
				padding: 0;
				position: relative;
				z-index: 1;
				${flex.left}
				${mixins.box}
				min-height: 333px;
			}
		}
	}

	.facts{
		padding: 0 39px 66px 55px;

		@media only screen and ${device.laptop} {
			padding: 0 calc(39px - (39px / ${mixins.indicator})) calc(66px - (66px / ${mixins.indicator})) calc(55px - (55px / ${mixins.indicator}));
			${flex.center}
			${mixins.box}
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
			max-width:calc(1700px - (1700px / ${mixins.indicator}));
			margin: 0 auto;
		}

		@media only screen and ${device.laptopL} {
			padding: 0 39px 66px 55px;
			${flex.center}
			${mixins.box}
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
			max-width: 1700px;
			margin: 0 auto;
		}

		&--item{
			${mixins.box}
			width: 100%;
			height: auto;
			margin: 0 0 68px;
			padding: 50px 23px 46px 30px;
			border-radius: 5.8px;
			background-color: rgba(5, 6, 9, 0.8);
			position: relative;

			@media only screen and ${device.laptop} {
				max-width: calc(390px - (390px / ${mixins.indicator}));
				margin: 0 81px 0;
				padding: calc(73px - (73px / ${mixins.indicator})) calc(32px - (32px / ${mixins.indicator})) calc(73px - (73px / ${mixins.indicator})) calc(42px - (42px / ${mixins.indicator}));

				&:first-of-type{
					margin: 0;
				}

				&:last-of-type{
					margin: 0;
				}

				&:nth-child(2){
					margin: calc(90px - (90px / ${mixins.indicator})) 0 0 0;
				}

				&:nth-child(3){
					margin: calc(244px - (244px / ${mixins.indicator})) 0 0 0;
				}
			}

			@media only screen and ${device.laptopL} {
				max-width: 390px;
				margin: 0 162px 0;
				padding: 73px 32px 73px 42px;

				&:first-of-type{
					margin: 0;
				}

				&:last-of-type{
					margin: 0;
				}

				&:nth-child(2){
					margin: 90px 0 0 0;
				}

				&:nth-child(3){
					margin: 244px 0 0 0;
				}
			}

			p{
				${fonts.font_00}
  			font-size: 1.73rem;
				font-weight: 500;
				line-height: 1.25;
				letter-spacing: normal;

				@media only screen and ${device.laptop} {
					font-size: 2.4rem;
				}
			}

			&:before{
				position: absolute;
				content: '';
				width: 34px;
				height: 34px;
				display: block;
				left: -17px;
				top: 12px;
				background-image: url(${starbig});
				background-repeat: no-repeat;
				background-size: cover;

				@media only screen and ${device.laptop} {
					width: calc(45px - (45px / ${mixins.indicator}));
					height: calc(45px - (45px / ${mixins.indicator}));
					left: calc(-17px - (-17px / ${mixins.indicator}));
					top: calc(12px - (12px / ${mixins.indicator}));
				}

				@media only screen and ${device.laptopL} {
					width: 45px;
					height: 45px;
					left: -17px;
					top: 12px;
				}
			}
		}
	}
`

export const Animation = createGlobalStyle `
	#one-anchor, .awards--type__tv, .awards--type__india, #two-anchor, .protagonistas--content__text, #three-anchor, .one-item-estructure, .one-item, .two-item-estructure, .two-item, .three-item-estructure, .three-item, .four-item-estructure, .four-item, .five-item-estructure, .five-item, .six-item-estructure, .six-item, #four-anchor, #four-text, #five-anchor, #five, .carousel-container, .timeline-container {}

	// One

	#one {

		.carousel {
			opacity: 0.5;
			transform: translate(0,-5%);
			transition: all 5s ease;

			&:hover {
				opacity: 1;
				transform: translate(0,0);
			}
		}

		&.animate {

			.carousel  {
				opacity: 1;
				transform: translate(0,0);
			}

		}
	}
`