import React, { Fragment, useState, useEffect } from 'react';

//Libraries
import Lottie from 'react-lottie-player';
import { useMediaQuery } from 'react-responsive';

//Animations
import MenuBotonAnimation from '../../assets/animaciones/boton.json';

const Boton = ({passToggleButton}) => {

	//State Animations
	const [toggleButton, setToggleButton] = useState(passToggleButton);
	const [segmentFrom, setSegmentFrom] = useState(0);
  const [segmentTo, setSegmentTo] = useState(60);
  const [segmentsEnabled, setSegmentsEnabled] = useState(true);
  const [play, setPlay] = useState(false);
  const [loop, setLoop] = useState(false);
  const [loopTimes, setLoopTimes] = useState(0);
  const [speed, setSpeed] = useState(2);
  const [direction, setDirection] = useState(1);
  const segments = [segmentFrom, segmentTo];

	const [log, setLog] = useState([]);
  const addLog = (v) => setLog(l => [v, ...l]);

	// State of boton
	useEffect(() => {
		if(passToggleButton) {
			setSegmentFrom(60);
			setSegmentTo(120);
			setToggleButton(false);
		}else{
			setSegmentFrom(0);
			setSegmentTo(60);
			setToggleButton(true);
		}
		setPlay(true);
	}, [passToggleButton])

	//Const Mediaqueries
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

	//Menu Botton
	const FPlayButtonMenu = () => {
		if(toggleButton) {
			setSegmentFrom(60);
			setSegmentTo(120);
			setToggleButton(false);
		}else{
			setSegmentFrom(0);
			setSegmentTo(60);
			setToggleButton(true);
		}
		setPlay(true);
	}

	return (
		<Fragment>
			{isTabletOrMobile &&
				<Lottie
					loop={loop}
					speed={speed}
					play={play}
					animationData={MenuBotonAnimation}
					direction={direction}
					segments={segmentsEnabled && segments}
					style={{ width: 60, height: 60, marginBottom: 0, alignSelf: 'center', cursor: 'pointer' }}
					onComplete={() => addLog('complete')}
					onLoopComplete={() => addLog('loopComplete')}
					onEnterFrame={() => { /* addLog('enterFrame') */ }}
					onSegmentStart={() => addLog('segmentStart')}
					onLoad={() => addLog('load')}
					onClick={() => FPlayButtonMenu()}
				/>
			}
			{isDesktopOrLaptop &&
				<Lottie
					loop={loop}
					speed={speed}
					play={play}
					animationData={MenuBotonAnimation}
					direction={direction}
					segments={segmentsEnabled && segments}
					style={{ width: 60, height: 60, marginBottom: 0, alignSelf: 'center', cursor: 'pointer' }}
					onComplete={() => addLog('complete')}
					onLoopComplete={() => addLog('loopComplete')}
					onEnterFrame={() => { /* addLog('enterFrame') */ }}
					onSegmentStart={() => addLog('segmentStart')}
					onLoad={() => addLog('load')}
					onClick={() => FPlayButtonMenu()}
				/>
			}
		</Fragment>
	)
}

export default Boton;