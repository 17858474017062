import React, { Fragment, useEffect } from 'react';

//Libraries
import ReactPlayer from 'react-player'
import { useMediaQuery } from 'react-responsive';
import Aos from 'aos';
import 'aos/dist/aos.css';

//Layout

//Styles
import { ProtagonistasStyles } from '../../styles/gstyles';

//Images
import Descarga from '../../assets/imagenes/descarga.jpeg';

const SectionTwo = () => {

	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);

	//Const Mediaqueries
	const isTabletOrMobile = useMediaQuery({
		query: '(min-width: 350px) and (max-width: 1024px)'
	})
	const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px) and (max-width: 1439px)'
  })
	const isBigScreen = useMediaQuery({
		query: '(min-width: 1440px)'
	})
  const isPortrait = useMediaQuery({
		query: '(orientation: portrait)'
	})
  const isRetina = useMediaQuery({
		query: '(min-resolution: 2dppx)'
	})

	return (
		<Fragment>
			{isTabletOrMobile &&
				<h2 id="two-anchor" data-aos="fade-down">La voz de sus<br /> protagonistas</h2>
			}

			{isDesktopOrLaptop &&
				<h2 id="two-anchor" data-aos="fade-left">La voz de<br /> sus protagonistas</h2>
			}

			{isBigScreen &&
				<h2 id="two-anchor" data-aos="fade-left">La voz de<br /> sus protagonistas</h2>
			}


			{/* // Protagonistas Styles */}
			<ProtagonistasStyles />

			<div className="protagonistas" data-aos={isTabletOrMobile ? "fade-down" : "fade-up"}>
				<div className="protagonistas--content">
					<p className="protagonistas--content__text">Después de hacer serenatas, cantarle a 10 o 15 personas máximo y tocar puertas en busca de oportunidades, estos artistas pasaron por Yo Me Llamo dándole inicio a una nueva etapa de sus carreras musicales. Una vez salieron del programa llenaron estadios, visitaron diferentes países y hoy en día siguen conquistando al público con las interpretaciones de sus dobles perfectos.</p>

					<div className="protagonistas--content__video">
						{isTabletOrMobile &&
							<ReactPlayer
								url='https://www.youtube.com/watch?v=zIs1BVY6dpY'
								width='100%'
								height='50vw'
							/>
						}

						{isDesktopOrLaptop &&
							<ReactPlayer
								url='https://www.youtube.com/watch?v=zIs1BVY6dpY'
								width='100%'
								height='472.5px'
							/>
						}

						{isBigScreen &&
							<ReactPlayer
								url='https://www.youtube.com/watch?v=zIs1BVY6dpY'
								width='100%'
								height='705px'
							/>
						}
					</div>
				</div>
			</div>
		</Fragment>
	 );
}

export default SectionTwo;